import { FunctionComponent, PropsWithChildren } from 'react'
import { Box } from '@mui/system'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { t } from '@lingui/macro'
import { HCP_PROS } from '../../api/hcp-pros'

export const PROSelectorComponent: FunctionComponent<PropsWithChildren<{ handleChange: Function; selectedPRO: string; isLoading?: boolean }>> = (
    props
) => {
    const { selectedPRO, handleChange } = props

    const onChange = (event: SelectChangeEvent) => {
        handleChange(event.target.value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                marginBottom: '20px'
            }}
        >
            <Select id='pro-select' sx={{ width: '100%' }} value={selectedPRO} label={t`Select PRO`} onChange={onChange} disabled={props.isLoading}>
                {HCP_PROS.map((pro) => (
                    <MenuItem key={pro} value={pro}>
                        {pro}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    )
}
