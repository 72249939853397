import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { PatientsExperiencingFallsAPI, SiteLevelBenchmarkingService } from '../service'
import { PatientsExperiencingFallsState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getPatientsExperiencingFalls = (state: { patientsexperiencingfalls: PatientsExperiencingFallsState }) => state.patientsexperiencingfalls

export function createPatientsExperiencingFallsSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getPatientsExperiencingFalls>) {
        try {
            const patientsexperiencingfalls: PatientsExperiencingFallsState = yield select(getPatientsExperiencingFalls)
            if (
                patientsexperiencingfalls.lastDateRange !== action.payload.dateRange ||
                patientsexperiencingfalls.lastOrgId !== action.payload.orgId
            ) {
                yield put(siteLevelBenchmarkingActions.setPatientsExperiencingFallsIsLoading(true))
                const result: PatientsExperiencingFallsAPI.Responses = yield call(
                    siteLevelBenchmarkingService.patientsExperiencingFallsAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === PatientsExperiencingFallsAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setPatientsExperiencingFalls(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setPatientsExperiencingFallsLastQuery(action.payload.dateRange, action.payload.orgId))
                } else if (result.type === PatientsExperiencingFallsAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === PatientsExperiencingFallsAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in PatientsExperiencingFallsAPI'))
                } else if (result.type === PatientsExperiencingFallsAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in PatientsExperiencingFallsSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setPatientsExperiencingFallsIsLoading(false))
        }
    }
}

export type SiteLevelScores = ReturnType<typeof createPatientsExperiencingFallsSaga>
