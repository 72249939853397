import { Trans } from '@lingui/macro'
import { AppBar, Button, ButtonProps, Divider, Menu, MenuItem, Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import { withRouter } from '@om1/platform-routing'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps, connectRedux } from '@om1/platform-utils'
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { UserMenu } from '../../PageContainerComponent'
import { Org, OrganizationsState, siteLevelBenchmarkingActions } from '../../module/state'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'
import { ClinicalManagementPage } from '../ClinicalManagementPage/ClinicalManagementPage'
import { ClinicalOutcomesPage } from '../ClinicalOutcomesPage/ClinicalOutcomesPage'
import { ClinicalSitePerformacePage } from '../ClinicalSitePerformacePage/ClinicalSitePerformacePage'
import { PROsPage } from '../PROsPage/PROsPage'
import { PopulationPage } from '../PopulationPage/PopulationPage'
import { createLandingPageContentContainerComponent } from './LandingPageContentContainer'

export const SiteLevelTabBar: FunctionComponent<PropsWithChildren<{}>> = (props) => (
    <Box component='nav' bgcolor='#F1F1F1' display='flex'>
        {props.children}
    </Box>
)

interface SiteLevelTabBarBarButtonButtonProps extends ButtonProps {
    active?: boolean
    route: string
    disabled?: boolean
    preserveQueryParams?: boolean
    namespace?: string
}

export const SiteLevelTabBarBarButton: FunctionComponent<SiteLevelTabBarBarButtonButtonProps> = ({ active, route, ...props }) => {
    const history = useHistory() // Get the history object
    const location = useLocation() // Get the current location

    // Handle the button click by preserving query parameters
    const handleClick = () => {
        const sameRoute = route === location.pathname
        // Function to parse query string into an object
        function parseQueryString(queryString) {
            const params = new URLSearchParams(queryString)
            const queryObj = {}

            for (const [key, value] of params) {
                queryObj[key] = value
            }

            return queryObj
        }

        // Parse the query string
        const queryObject = parseQueryString(location.search)

        // Specify the key you want to remove (e.g., "population")
        const keyToRemove = props.namespace || ''

        // set the new query string
        let newQueryString = location.search

        // Check if the key exists in the query object
        if (keyToRemove in queryObject) {
            // Remove the key
            delete queryObject[keyToRemove]

            // Convert the modified object back to a query string
            newQueryString = Object.keys(queryObject)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`)
                .join('&')
        }

        history.push({
            pathname: route,
            search: props.preserveQueryParams ? (!sameRoute ? location.search : newQueryString) : ''
        })
    }

    return (
        <Button
            onClick={handleClick}
            aria-current={active ? 'true' : 'false'}
            disabled={props.disabled}
            sx={{
                minWidth: 128,
                marginRight: '10px',
                height: '100%',
                borderRadius: 0,
                border: '1px solid #CCCCCC',
                borderBottomWidth: 3,
                fontWeight: 'bold',
                borderBottomStyle: 'solid',
                borderBottomColor: active ? 'primary.main' : 'transparent',
                background: active ? '#FFFFFF' : 'transparent',
                '&:hover': {
                    borderBottomColor: active ? 'primary.main' : 'secondary.main',
                    background: active ? '#FFFFFF' : 'transparent'
                }
            }}
        >
            {props.children}
        </Button>
    )
}

const LandingPageContentContainer = createLandingPageContentContainerComponent<SiteLevelBenchmarkingState>()

export const tabs = [
    {
        name: <Trans>Clinical Site Performance</Trans>,
        localpath: 'clinicalsiteperformance',
        component: <ClinicalSitePerformacePage />
    },
    {
        name: <Trans>Population</Trans>,
        localpath: 'population',
        component: <PopulationPage />
    },
    {
        name: <Trans>PROs</Trans>,
        localpath: 'pros',
        component: <PROsPage />
    },
    {
        name: <Trans>Clinical Outcomes</Trans>,
        localpath: 'clinicaloutcomes',
        component: <ClinicalOutcomesPage />
    },
    {
        name: <Trans>Clinical Management</Trans>,
        localpath: 'clinicalmanagement',
        component: <ClinicalManagementPage />
    }
]

export function createSiteLevelBenchmarkingLandingPageComponent<TState extends { organizations: OrganizationsState }>() {
    return connectRedux(withRouter(SiteLevelBenchmarkingLandingPageComponent), (state: TState) => state.organizations, siteLevelBenchmarkingActions)
}

export type SiteLevelBenchmarkingLandingPageComponentProps = RoutedFrameworkComponentProps<
    {},
    { orgId: string; chartName: string },
    OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {
        logo?: React.ReactElement
        username?: string
        logoutFunction?: () => void
    }
>

export const SiteLevelBenchmarkingLandingPageComponent: React.FunctionComponent<SiteLevelBenchmarkingLandingPageComponentProps> = ({
    state,
    routing,
    props
}) => {
    const match = useRouteMatch()
    const location = useLocation()
    const history = useHistory()

    if (!match) {
        return null
    }

    const OrgMenu: FunctionComponent<{ selectedOrgName: string; orgs?: Org[]; chartName: string }> = (props) => {
        const history = useHistory()
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
        }
        const handleClose = () => {
            setAnchorEl(null)
        }

        useEffect(() => {
            if (props.selectedOrgName === undefined) {
                history.push('/')
            }
        }, [props.selectedOrgName, history])

        return (
            <div>
                <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        p: 0,
                        borderRadius: '0px',
                        marginLeft: '30px',
                        height: '30px',
                        transition: 'opacity 2s ease',
                        color: '#FFFFFF',
                        fontFamily: 'Metropolis',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        letterSpacing: '0',
                        lineHeight: '16px'
                    }}
                >
                    {props.selectedOrgName}
                </Button>
                <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    {[
                        <MenuItem key='placeholder' disabled value=''>
                            <Trans>Select Clinical Site</Trans>
                        </MenuItem>
                    ].concat(
                        props.orgs!.map((org: Org) => (
                            <MenuItem
                                key={org.name}
                                value={org.id}
                                onClick={() => {
                                    history.push(
                                        `/${org.id}/${props.chartName ? props.chartName : 'clinicalsiteperformance'}${history.location.search}`
                                    )
                                }}
                            >
                                {org.name}
                            </MenuItem>
                        ))
                    )}
                </Menu>
            </div>
        )
    }

    const org: Org = state.orgs.filter((org) => org.id === routing.pathParams.orgId)[0]

    return (
        <>
            <AppBar position='fixed' sx={{ backgroundColor: '#43454A' }}>
                <Toolbar variant='dense' sx={{ backgroundColor: '#43454A' }}>
                    {props.logo}
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} />
                    <OrgMenu selectedOrgName={org.name} orgs={state.orgs} chartName={routing.pathParams.chartName!} />
                    <UserMenu username={props.username} logoutFunction={props.logoutFunction} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingLeft: '10px'
                        }}
                    ></Box>
                </Toolbar>
            </AppBar>
            <Box
                paddingTop={7}
                component='main'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minWidth: 0
                }}
            >
                <ContentContainerStyle>
                    <ContentPadding padX padY>
                        <Box
                            sx={{
                                height: '40px',
                                color: '#55555A',
                                fontFamily: 'Arial',
                                fontSize: '28px',
                                letterSpacing: '0',
                                lineHeight: '25px',
                                textAlign: 'center',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                history.push(`/${org.id}/${'clinicalsiteperformance'}`)
                            }}
                        >
                            <Trans>Site-Level Benchmark Reports</Trans>
                        </Box>
                    </ContentPadding>
                    <ContentPadding padX padY>
                        <SiteLevelTabBar>
                            {tabs.map((tab) => (
                                <div key={`tabbuttondiv-${tab.localpath}`}>
                                    <SiteLevelTabBarBarButton
                                        key={`tabbutton-${tab.localpath}`}
                                        route={`/${routing.pathParams.orgId}/${tab.localpath}`}
                                        active={location.pathname.includes(tab.localpath)}
                                        preserveQueryParams={true}
                                        namespace={tab.localpath}
                                    >
                                        {tab.name}
                                    </SiteLevelTabBarBarButton>
                                </div>
                            ))}
                        </SiteLevelTabBar>
                    </ContentPadding>
                    <Divider sx={{ marginTop: '-10px' }} />
                    <LandingPageContentContainer />
                </ContentContainerStyle>
            </Box>
        </>
    )
}
