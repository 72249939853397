import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { PageContainerComponent } from './PageContainerComponent'
import { OrganizationsState, siteLevelBenchmarkingActions } from './module/state'
import { KeycloakState, keycloakActions } from './shared/authentication'
import { ConfigState, configActions } from './shared/config'

export function createPageContainerComponent<TState extends { organizations: OrganizationsState; keycloak: KeycloakState; config: ConfigState }>() {
    return connectRedux(
        withRouter(PageContainerComponent, {
            namespace: 'orgs',
            dataFetchAction: 'getOrganizations'
        }),
        (state: TState) => {
            return { ...state.organizations, ...state.keycloak, ...state.config }
        },
        { ...siteLevelBenchmarkingActions, ...keycloakActions, ...configActions }
    )
}

export type PageContainer = ReturnType<typeof createPageContainerComponent>
