import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { HospitalizationsRateAPI, SiteLevelBenchmarkingService } from '../service'
import { HospitalizationsRateState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const gethospitalizationsRate = (state: { hospitalizations: HospitalizationsRateState }) => state.hospitalizations

export function createHospitalizationsRateSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getHospitalizationsRate>) {
        try {
            const hospitalizationsRate: HospitalizationsRateState = yield select(gethospitalizationsRate)
            if (hospitalizationsRate.lastDateRange !== action.payload.dateRange || hospitalizationsRate.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setHospitalizationsRateIsLoading(true))
                const result: HospitalizationsRateAPI.Responses = yield call(
                    siteLevelBenchmarkingService.hospitalizationsRateAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === HospitalizationsRateAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setHospitalizationsRate(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setHospitalizationsRateLastQuery(action.payload.dateRange, action.payload.orgId))
                } else if (result.type === HospitalizationsRateAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === HospitalizationsRateAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in hospitalizationsRateAPI'))
                } else if (result.type === HospitalizationsRateAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in hospitalizationsRateSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setHospitalizationsRateIsLoading(false))
        }
    }
}

export type hospitalizationsRateSaga = ReturnType<typeof createHospitalizationsRateSaga>
