/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { FunctionComponent, PropsWithChildren } from 'react'
import { Box } from '@mui/system'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { t } from '@lingui/macro'
import { Button } from '@mui/material'

export const DateRangeSelectorComponent: FunctionComponent<
    PropsWithChildren<{ handleChange: Function; selectedValue: string; isLoading?: boolean }>
> = (props) => {
    const { handleChange, selectedValue } = props

    const selectableDates = [
        { label: t`1 month`, value: '1m' },
        { label: t`3 months`, value: '3m' },
        { label: t`6 months`, value: '6m' },
        { label: t`1 year`, value: '1y' },
        { label: t`all`, value: 'all' }
    ]

    const renderSelections = () =>
        selectableDates.map((option) => (
            <Box
                key={option.value}
                sx={{
                    border: option.value === selectedValue ? '1px solid #C1C6C8' : 'none'
                }}
            >
                <Button
                    disabled={option.value === selectedValue || props.isLoading}
                    sx={{
                        borderRadius: 0,
                        color: '#79A6D6',
                        cursor: 'pointer',
                        borderBottomWidth: 3,
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'transparent',
                        marginLeft: '10px',
                        marginRight: '10px',
                        background: 'transparent',
                        '&:hover': {
                            borderBottomColor: 'transparent'
                        },
                        '&:disabled': {
                            color: '#79A6D6'
                        }
                    }}
                    onClick={() => handleChange(option.value)}
                >
                    {option.label}
                </Button>
            </Box>
        ))

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '25px',
                transition: 'opacity 0.2s ease',
                opacity: props.isLoading ? 0.4 : 1
            }}
        >
            <ChevronLeftIcon sx={{ color: '#79A6D6' }} />
            {renderSelections()}
            <ChevronRightIcon sx={{ color: '#79A6D6' }} />
        </Box>
    )
}
