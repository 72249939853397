import { ActionsUnion, createAction } from '@om1/platform-utils'

export interface ConfigState {
    hideCOMeanChange: boolean
    hidePatientDemographics: boolean
    siteUnderConstruction: boolean
}

export const initialConfigState: ConfigState = {
    siteUnderConstruction: (process.env.REACT_APP_SITE_UNDER_CONSTRUCTION as string) === 'true',
    hideCOMeanChange: (process.env.REACT_APP_HIDE_CO_MEAN_CHANGE as string) === 'true',
    hidePatientDemographics: (process.env.REACT_APP_HIDE_PATIENT_DEMOGRAPHICS as string) === 'true'
}

export const getConfigState = (state: { config: ConfigState }) => state.config

export enum ConfigActionTypes {
    SET_SITE_UNDER_CONSTRUCTION = '@@config/set-site-under-construction',
    SET_HIDE_CO_MEAN_CHANGE = '@@config/set-hide-co-mean-change',
    SET_HIDE_PATIENT_DEMOGRAPHICS = '@@config/set-hide-patient-demographics'
}

export const configActions = {
    setSiteUnderConstruction: (config: boolean) => createAction(ConfigActionTypes.SET_SITE_UNDER_CONSTRUCTION, { siteUnderConstruction: config }),
    setHideCOMeanChange: (config: boolean) => createAction(ConfigActionTypes.SET_HIDE_CO_MEAN_CHANGE, { hideCOMeanChange: config }),
    setHidePatientDemographics: (config: boolean) =>
        createAction(ConfigActionTypes.SET_HIDE_PATIENT_DEMOGRAPHICS, { hidePatientDemographics: config })
}

export type ConfigActions = ActionsUnion<typeof configActions>

export function configReducer(previousState: ConfigState | undefined, action: ConfigActions): ConfigState {
    const state = previousState || initialConfigState

    switch (action.type) {
        case ConfigActionTypes.SET_SITE_UNDER_CONSTRUCTION:
            return {
                ...state,
                siteUnderConstruction: action.payload.siteUnderConstruction
            }
        case ConfigActionTypes.SET_HIDE_CO_MEAN_CHANGE:
            return {
                ...state,
                hideCOMeanChange: action.payload.hideCOMeanChange
            }
        case ConfigActionTypes.SET_HIDE_PATIENT_DEMOGRAPHICS:
            return {
                ...state,
                hidePatientDemographics: action.payload.hidePatientDemographics
            }
        default:
            return state
    }
}
