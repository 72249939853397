import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { DMTBreakdownByRouteState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { DMTBreakdownByRouteComponent } from './DMTBreakdownByRouteComponent'

export function createDMTBreakdownByRouteComponent<
    TState extends { dmtBreakdownByRoute: DMTBreakdownByRouteState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(DMTBreakdownByRouteComponent, {
            namespace: 'population',
            dataFetchAction: 'getDMTBreakdownByRouteChart'
        }),
        (state: TState) => {
            return { ...state.dmtBreakdownByRoute, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type DMTBreakdownByRoute = ReturnType<typeof createDMTBreakdownByRouteComponent>
