/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { DMTBreakdownByMedicationState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { FunctionComponent, useEffect } from 'react'
import { Trans } from '@lingui/react'
import { Box } from '@mui/system'
import { Alert, AlertTitle, CircularProgress } from '@mui/material'
import { cloneDeep } from 'lodash'
import Plot from 'react-plotly.js'
import { plotLayout } from './plot-layout'
import { SiteLegendComponent } from './SiteLegendComponent'

export type DMTBreakdownByMedicationComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    DMTBreakdownByMedicationState & OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

export const DMTBreakdownByMedicationComponent: FunctionComponent<DMTBreakdownByMedicationComponentProps> = ({ state, routing, actions }) => {
    useEffect(() => {
        actions.getDMTBreakdownByMedicationChart({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    const renderPlots = () => {
        if (state.data === undefined) {
            return (
                <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                    {state.isLoading && <CircularProgress />}
                    {!state.isLoading && (
                        <Alert severity='error' sx={{ width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            Error loading Process Performance Chart Data
                        </Alert>
                    )}
                </Box>
            )
        }

        return (
            <Plot
                data={cloneDeep(state.data['data'])}
                layout={{ ...cloneDeep(state.data['layout']), ...plotLayout }}
                style={{ width: '100%', height: '100%', opacity: state.isLoading ? 0.4 : 1 }}
                config={{
                    modeBarButtonsToRemove: [
                        'toImage',
                        'zoom2d',
                        'pan2d',
                        'select2d',
                        'lasso2d',
                        'zoomIn2d',
                        'zoomOut2d',
                        'autoScale2d',
                        'resetScale2d'
                    ],
                    displaylogo: false,
                    staticPlot: state.isLoading
                }}
                useResizeHandler={true}
            />
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px',
                transition: 'opacity 2s ease'
            }}
        >
            <h3>
                <Trans id='DMT Breakdown By Medication'>DMT Breakdown</Trans>
            </h3>
            <SiteLegendComponent />
            {renderPlots()}
        </Box>
    )
}
