import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { keycloakActions } from '../../shared/authentication'
import { EnrollmentChartAPI, SiteLevelBenchmarkingService } from '../service'
import { EnrollmentChartState, siteLevelBenchmarkingActions } from '../state'

export const getEnrollmentChart = (state: { enrollmentChart: EnrollmentChartState }) => state.enrollmentChart

export function createEnrollmentChartSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getEnrollmentChart>) {
        try {
            const enrollmentChart: EnrollmentChartState = yield select(getEnrollmentChart)
            if (enrollmentChart.lastDateRange !== action.payload.dateRange || enrollmentChart.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setEnrollmentChartIsLoading(true))
                const result: EnrollmentChartAPI.Responses = yield call(
                    siteLevelBenchmarkingService.enollmentChartAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === EnrollmentChartAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setEnrollmentChart(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setEnrollmentChartLastQuery(action.payload.dateRange, action.payload.orgId))
                } else if (result.type === EnrollmentChartAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === EnrollmentChartAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in EnrollmentChartAPI'))
                } else if (result.type === EnrollmentChartAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in EnrollmentChartSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setEnrollmentChartIsLoading(false))
        }
    }
}

export type EnrollmentChartSaga = ReturnType<typeof createEnrollmentChartSaga>
