import * as Keycloak from 'keycloak-js'
import { debounce } from 'redux-saga/effects'
import { KeycloakActionTypes } from './keycloak-state'

export function createKeycloakLogoutSaga(keycloak: Keycloak.KeycloakInstance) {
    return function* () {
        yield keycloak.logout()
    }
}

export function createKeycloakLoginSaga(keycloak: Keycloak.KeycloakInstance) {
    return function* () {
        yield keycloak.login()
    }
}

export type KeycloakSaga = ReturnType<typeof createKeycloakSaga>

export function createKeycloakSaga(keycloak: Keycloak.KeycloakInstance) {
    const keycloakLogoutSaga = createKeycloakLogoutSaga(keycloak)
    const keycloakLoginSaga = createKeycloakLoginSaga(keycloak)
    return function* () {
        yield debounce(0, KeycloakActionTypes.LOGOUT, keycloakLogoutSaga)
        yield debounce(0, KeycloakActionTypes.LOGIN, keycloakLoginSaga)
    }
}
