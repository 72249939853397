import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { EthnicityAPI, SiteLevelBenchmarkingService } from '../service'
import { EthnicityState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getEthnicity = (state: { ethnicity: EthnicityState }) => state.ethnicity

export function createEthnicitySaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getEthnicityChart>) {
        try {
            const ethnicity: EthnicityState = yield select(getEthnicity)
            if (ethnicity.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setEthnicityChartIsLoading(true))
                const result: EthnicityAPI.Responses = yield call(siteLevelBenchmarkingService.ethnicityAPI, getToken(), action.payload.orgId)
                if (result.type === EthnicityAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setEthnicityChart(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setEthnicityChartLastQuery(action.payload.orgId))
                } else if (result.type === EthnicityAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === EthnicityAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in EthnicityAPI'))
                } else if (result.type === EthnicityAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in ethnicitySaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setEthnicityChartIsLoading(false))
        }
    }
}

export type ethnicitySaga = ReturnType<typeof createEthnicitySaga>
