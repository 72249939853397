import { PlatformAPI } from '@om1/platform-api'
import { ResponseTypes, createResponse } from '@om1/platform-utils'
import { hcpEndpoints } from '../../api/hcp-endpoints'
import { PatientBaselineDemographicsData } from '../state'

export const SUCCESS = 'success'
export const UNAUTHORIZED = 'unauthorized'
export const NOTFOUND = 'notfound'
export const ERROR = 'error'

const success = (response: PatientBaselineDemographicsData) => createResponse(SUCCESS, { response: response })

const notfound = (response: string) => createResponse(NOTFOUND, { response: response })

const unauthorized = (response: string) => createResponse(UNAUTHORIZED, { response: response })

const error = (response: string) => createResponse(ERROR, { response: response })

export const Responses = {
    success,
    unauthorized,
    error,
    notfound
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Responses = ResponseTypes<typeof Responses>

export function create(platformApi: PlatformAPI) {
    return async function service(token: string | undefined, organizationId: string) {
        const [response, status] = await platformApi(hcpEndpoints.ptBaselineDemographics, { organizationId }, 'GET', token)
        if (status === 200 && response) {
            return success(JSON.parse(response) as PatientBaselineDemographicsData)
        } else if (status === 401 || status === 403) {
            return unauthorized(response)
        } else if (status === 404 && response) {
            return notfound(response)
        } else if (status === 500 && response) {
            return error(response)
        }
    }
}
