/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { Alert, AlertTitle, CircularProgress, styled } from '@mui/material'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { cloneDeep } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import Plot from 'react-plotly.js'
import { OrganizationsState, PatientBaselineDemographicsState, PlotlyResponse, siteLevelBenchmarkingActions } from '../state'
import { SiteLegendComponent } from './SiteLegendComponent'
import { plotLayout } from './plot-layout'

export type PatientBaselineDemographicsComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    PatientBaselineDemographicsState & OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

const SyledLabelBox = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4c4c4c;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
`

const baseLineLabel = (std_dev: number) => {
    const render_std_dev =
        std_dev === null ? (
            'No data available'
        ) : (
            <>
                {std_dev.toFixed(1)} {t`Std. deviation`}
            </>
        )

    return (
        <Box>
            <SyledLabelBox>
                <Trans id='Mean age at baseline'>Mean age at baseline</Trans>
            </SyledLabelBox>
            <SyledLabelBox>
                <span>{render_std_dev}</span>
            </SyledLabelBox>
        </Box>
    )
}

const SyledDataPointBox = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

const SyledMyOrgDataPoint = styled(Box)`
    height: 41px;
    color: #8bb8e8;
    font-family: Arial;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
`

const SyledAllOrgsDataPoint = styled(Box)`
    height: 41px;
    color: #aaaeb0;
    font-family: Arial;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
`

export const PatientBaselineDemographicsComponent: FunctionComponent<PatientBaselineDemographicsComponentProps> = ({ state, routing, actions }) => {
    useEffect(() => {
        actions.getPTBaselineDemographics({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    const renderPieCharts = (data: PlotlyResponse) => (
        <Plot
            data={cloneDeep(data['data'])}
            layout={{ ...cloneDeep(data['layout']), ...plotLayout }}
            style={{ width: '100%', height: '100%', opacity: state.isLoading ? 0.4 : 1 }}
            config={{
                modeBarButtonsToRemove: ['toImage', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d'],
                displaylogo: false,
                staticPlot: state.isLoading
            }}
            useResizeHandler={true}
        />
    )

    const renderMyOrgMeanAge = (mean_age: number) =>
        mean_age === null ? <></> : <SyledMyOrgDataPoint>{Math.trunc(mean_age)} yrs</SyledMyOrgDataPoint>
    const renderAllOrgsMeanAge = (mean_age: number) =>
        mean_age === null ? <></> : <SyledAllOrgsDataPoint>{Math.trunc(mean_age)} yrs</SyledAllOrgsDataPoint>

    const renderCharts = () => {
        if (state.data === undefined) {
            return (
                <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                    {state.isLoading && <CircularProgress />}
                    {!state.isLoading && (
                        <Alert severity='error' sx={{ width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            Error loading Patient Demographics at Baseline
                        </Alert>
                    )}
                </Box>
            )
        }

        const { my_org_mean_age, other_org_mean_age, sex_pies, ms_subtype_pies, age_breakdown } = state.data

        return (
            <>
                <SiteLegendComponent />
                <Box width='100%' display='flex' flexDirection='column'>
                    <Box width='100%' display='flex' flexDirection='row'>
                        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-around'>
                            <SyledDataPointBox>
                                {renderMyOrgMeanAge(my_org_mean_age.mean)}
                                {baseLineLabel(my_org_mean_age.std_dev)}
                            </SyledDataPointBox>
                            <SyledDataPointBox>
                                {renderAllOrgsMeanAge(other_org_mean_age.mean)}
                                {baseLineLabel(other_org_mean_age.std_dev)}
                            </SyledDataPointBox>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' width='100%' justifyContent='space-around'>
                        {renderPieCharts(sex_pies)}
                        {renderPieCharts(ms_subtype_pies)}
                    </Box>
                </Box>
                <Plot
                    data={cloneDeep(age_breakdown['data'])}
                    layout={{ ...cloneDeep(age_breakdown['layout']), ...plotLayout }}
                    style={{ width: '100%', height: '100%', opacity: state.isLoading ? 0.4 : 1 }}
                    config={{
                        modeBarButtonsToRemove: [
                            'toImage',
                            'zoom2d',
                            'pan2d',
                            'select2d',
                            'lasso2d',
                            'zoomIn2d',
                            'zoomOut2d',
                            'autoScale2d',
                            'resetScale2d'
                        ],
                        displaylogo: false,
                        staticPlot: state.isLoading
                    }}
                    useResizeHandler={true}
                />
            </>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px'
            }}
        >
            <h3>
                <Trans id='Patient Demographics at Baseline'>Patient Demographics at Baseline</Trans>
            </h3>
            {renderCharts()}
        </Box>
    )
}
