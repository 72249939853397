import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { HospitalizationsRateState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { HospitalizationsRateComponent } from './HospitilizationsRateComponent'

export function createHospitalizationsRateComponent<
    TState extends { hospitalizations: HospitalizationsRateState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(HospitalizationsRateComponent, { namespace: 'clinicalmanagement', dataFetchAction: 'getHospitalizationsRate' }),
        (state: TState) => {
            return { ...state.hospitalizations, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type hospitalizationsRate = ReturnType<typeof createHospitalizationsRateComponent>
