import * as React from 'react'
import { PropsWithChildren } from 'react'
import { HCPApiConfigInterface, hcpApiConfig } from './api/hcp-api-config'
import { KeycloakConfigInterface, keycloakConfig } from './shared/authentication'

// any provider or package needing environment variables get initialized here
export interface SiteLevelBenchmarkingConfigInterface extends KeycloakConfigInterface, HCPApiConfigInterface {}

export const UACConfigContext = React.createContext<Partial<SiteLevelBenchmarkingConfigInterface>>({})

export const Config: React.FunctionComponent<PropsWithChildren<{ config: SiteLevelBenchmarkingConfigInterface }>> = ({ children, config }) => {
    const validatedConfig = {
        ...config
    }
    return <UACConfigContext.Provider value={validatedConfig}>{children}</UACConfigContext.Provider>
}

export const config: SiteLevelBenchmarkingConfigInterface = {
    ...keycloakConfig,
    ...hcpApiConfig
}
