import { debounce } from 'redux-saga/effects'
import { HcpApi } from '../../api/hcp-fetch'
import { createSiteLevelBenchmarkingService } from '../service'
import { SiteLevelBenchmarkingActionTypes } from '../state'
import { createAveragePROScoresSaga } from './average-pro-scores'
import { createCOAssessmentSaga } from './co-assessment'
import { createDMTBreakdownByMedicationChartSaga } from './dmt-breakdown-by-medication'
import { createDMTBreakdownByRouteChartSaga } from './dmt-breakdown-by-route'
import { createEmploymentAndInsurance } from './employment-and-insurance'
import { createEnrollmentChartSaga } from './enrollment-chart'
import { createERUtilizationsRateSaga } from './er-utilizations-rate'
import { createEthnicitySaga } from './ethnicity'
import { createGetOrganizationsSaga } from './get-organizations'
import { createHospitalizationsRateSaga } from './hospitilizations-rate'
import { createGetMSDurationChartSaga } from './ms-duration'
import { createPatientBaselineDemographicsChartSaga } from './patient-baseline-demographics'
import { createDemographicsOverTimeSaga } from './patient-demographics-over-time'
import { createPatientsExperiencingFallsSaga } from './patients-experiencing-falls'
import { createPatientsExperiencingRelapseSaga } from './patients-experiencing-relapse'
import { createPatientsTreatedWithDmtSaga } from './patients-treated-with-dmt'
import { createRaceSaga } from './race'
import { createRelapseHistoryAndRateSaga } from './relapse-history-and-rate'

export function createSiteLevelBenchmarkingSaga(hcpApi: HcpApi, getToken: () => string | undefined) {
    const patientsExperiencingRelapseSaga = createPatientsExperiencingRelapseSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const patientsTreatedWithDmtSaga = createPatientsTreatedWithDmtSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const patientsExperiencingFallsSaga = createPatientsExperiencingFallsSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const averagePROScoresSaga = createAveragePROScoresSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const enrollmnentChartSaga = createEnrollmentChartSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const getOrganizations = createGetOrganizationsSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const dmtBreakdownByRouteChartSaga = createDMTBreakdownByRouteChartSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const dmtBreakdownByMedicationChartSaga = createDMTBreakdownByMedicationChartSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const employmentAndInsuranceSaga = createEmploymentAndInsurance(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const relapseHistoryAndRateSaga = createRelapseHistoryAndRateSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const hospitalizationsRateSaga = createHospitalizationsRateSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const erUtilizationsRateSaga = createERUtilizationsRateSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const raceSaga = createRaceSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const ethnicitySaga = createEthnicitySaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const coAssessmentSaga = createCOAssessmentSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const ptBaselineDemographicsSaga = createPatientBaselineDemographicsChartSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const msDurationSaga = createGetMSDurationChartSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)
    const demographicsOverTimeSaga = createDemographicsOverTimeSaga(createSiteLevelBenchmarkingService(hcpApi), getToken)

    return function* () {
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_PATIENTS_EXPERIENCING_RELAPSE_RATE, patientsExperiencingRelapseSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_PATIENTS_TREATED_WITH_DMT, patientsTreatedWithDmtSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_PATIENTS_EXPERIENCING_FALLS, patientsExperiencingFallsSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_AVERAGE_PROS_SCORES, averagePROScoresSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_ENROLLMENT_CHART, enrollmnentChartSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_SITE_LEVEL_ORGANIZATIONS, getOrganizations)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_DMT_BREAKDOWN_BY_ROUTE_CHART, dmtBreakdownByRouteChartSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_DMT_BREAKDOWN_BY_MEDICATION_CHART, dmtBreakdownByMedicationChartSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_EMPLOYMENT_AND_INSURANCE, employmentAndInsuranceSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_RELAPSE_HISTORY_AND_RATE, relapseHistoryAndRateSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_HOSPITALIZATIONS_RATE, hospitalizationsRateSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_ER_UTILIZATIONS_RATE, erUtilizationsRateSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_RACE, raceSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_ETHNICITY, ethnicitySaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_CO_ASSESSMENT, coAssessmentSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_PT_BASELINE_DEMOGRAPHICS, ptBaselineDemographicsSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_MS_DURATION, msDurationSaga)
        yield debounce(0, SiteLevelBenchmarkingActionTypes.GET_DEMOGRAPHICS_OVER_TIME, demographicsOverTimeSaga)
    }
}
