import { hcpEndpoints } from './hcp-endpoints'

export const HCP_DEMOGRAPHICS = {
    'Mean Age Over Time': hcpEndpoints.meanAgeOverTime,
    'Percent Female Over Time': hcpEndpoints.percentFemaleOverTime,
    'Percent Non-White Over Time': hcpEndpoints.percentNonWhiteOverTime,
    'Percent Relapse Remitting MS Over Time': hcpEndpoints.percentRelapseRemittingOverTime,
    'Percent Commercial Insurance Over Time': hcpEndpoints.percentCommercialInsuranceOverTime,
    'Percent Receiving MRI Scan in the Last 12 Months': hcpEndpoints.percentReceivingMriAnnually
} as const
export const HCP_DEMOGRAPHICS_ARRAY = Object.keys(HCP_DEMOGRAPHICS)
export const DEFAULT_DEMOGRAPHIC = HCP_DEMOGRAPHICS_ARRAY[0]
export type HCPDemographicsType = typeof HCP_DEMOGRAPHICS_ARRAY[number]
