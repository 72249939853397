import { createPatientsExperiencingRelapseComponent } from '../../module/components/PatientsExperiencingRelapseRate'
import { Box } from '@mui/system'
import { createPatientsExperiencingFallsComponent } from '../../module/components/PatientsExperiencingFalls'
import { createPatientsTreatedWithDmtComponent } from '../../module/components/PatientsTreatedWithDmt'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'
import { createRelapseHistoryAndRateComponent } from '../../module/components/RelapseHistoryAndRate'
import { createERUtilizationsRateComponent } from '../../module/components/ERUtilizationsRate'
import { createHospitalizationsRateComponent } from '../../module/components/HospitilizationsRate'

const RelapseHistoryAndRateComponent = createRelapseHistoryAndRateComponent<SiteLevelBenchmarkingState>()
const PatientsExperiencingRelapseComponent = createPatientsExperiencingRelapseComponent<SiteLevelBenchmarkingState>()
const PatientsExperiencingFallsComponent = createPatientsExperiencingFallsComponent<SiteLevelBenchmarkingState>()
const PatientsTreatedWithDmtComponent = createPatientsTreatedWithDmtComponent<SiteLevelBenchmarkingState>()
const ERUtilizationsRateComponent = createERUtilizationsRateComponent<SiteLevelBenchmarkingState>()
const HospitalizationsRateComponent = createHospitalizationsRateComponent<SiteLevelBenchmarkingState>()

export function ClinicalManagementPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <RelapseHistoryAndRateComponent />
            <PatientsExperiencingRelapseComponent />
            <PatientsExperiencingFallsComponent />
            <ERUtilizationsRateComponent />
            <HospitalizationsRateComponent />
            <PatientsTreatedWithDmtComponent />
        </Box>
    )
}
