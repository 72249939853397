import { t } from '@lingui/macro'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Box } from '@mui/system'
import { FunctionComponent, PropsWithChildren } from 'react'
import { HCPAssessmentType, HCP_ASSESSMENTS } from '../../api/hcp-assessments'

export const ClinicalAssessmentSelectorComponent: FunctionComponent<
    PropsWithChildren<{ handleChange: Function; selectedAssessment: HCPAssessmentType; isLoading?: boolean }>
> = (props) => {
    const { selectedAssessment, handleChange } = props

    const onChange = (event: SelectChangeEvent) => {
        handleChange(event.target.value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                marginBottom: '20px'
            }}
        >
            <Select
                id='pro-select'
                sx={{ width: '100%' }}
                value={selectedAssessment}
                label={t`Select Assessment`}
                onChange={onChange}
                disabled={props.isLoading}
            >
                {HCP_ASSESSMENTS.map((assessment) => (
                    <MenuItem key={assessment} value={assessment}>
                        {assessment}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    )
}
