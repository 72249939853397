import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { RelapseHistoryAndRateAPI, SiteLevelBenchmarkingService } from '../service'
import { RelapseHistoryAndRateState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getRelapseHistoryAndRate = (state: { relapseHistoryAndRate: RelapseHistoryAndRateState }) => state.relapseHistoryAndRate

export function createRelapseHistoryAndRateSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getRelapseHistoryAndRate>) {
        try {
            const relapseHistoryAndRate: RelapseHistoryAndRateState = yield select(getRelapseHistoryAndRate)
            if (relapseHistoryAndRate.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setRelapseHistoryAndRateIsLoading(true))
                const result: RelapseHistoryAndRateAPI.Responses = yield call(
                    siteLevelBenchmarkingService.relapseHistoryAndRateApi,
                    getToken(),
                    action.payload.orgId
                )
                if (result.type === RelapseHistoryAndRateAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setRelapseHistoryAndRate(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setRelapseHistoryAndRateLastQuery(action.payload.orgId))
                } else if (result.type === RelapseHistoryAndRateAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === RelapseHistoryAndRateAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in RelapseHistoryAndRateAPI'))
                } else if (result.type === RelapseHistoryAndRateAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in RelapseHistoryAndRateSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setRelapseHistoryAndRateIsLoading(false))
        }
    }
}

export type RelapseHistoryAndRateSaga = ReturnType<typeof createRelapseHistoryAndRateSaga>
