import { Trans } from '@lingui/macro'
import { Box } from '@mui/system'
import { ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { ReactComponent as ConstructionIcon } from '../../assets/construction.svg'

export function UnderConstructionPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <ContentPadding padX padY>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh'
                    }}
                >
                    <Trans>The Site-Level Benchmark Application is currently under construction.</Trans>
                    <ConstructionIcon color='#43454A' />
                </Box>
            </ContentPadding>
        </Box>
    )
}
