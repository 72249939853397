import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { MSDurationAPI, SiteLevelBenchmarkingService } from '../service'
import { MSDurationState, PatientBaselineDemographicsState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getMSDurationChart = (state: { msDuration: MSDurationState }) => state.msDuration

export function createGetMSDurationChartSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getMSDuration>) {
        try {
            const msduration: PatientBaselineDemographicsState = yield select(getMSDurationChart)
            if (msduration.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setMSDurationIsLoading(true))
                const result: MSDurationAPI.Responses = yield call(siteLevelBenchmarkingService.msDurationAPI, getToken(), action.payload.orgId)
                if (result.type === MSDurationAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setMSDuration(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setMSDurationLasQuery(action.payload.orgId))
                } else if (result.type === MSDurationAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === MSDurationAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in MSDurationAPI'))
                } else if (result.type === MSDurationAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in createMSDurationChartSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setMSDurationIsLoading(false))
        }
    }
}

export type DMTBreakdownByRouteChartSaga = ReturnType<typeof createGetMSDurationChartSaga>
