import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, PatientsExperiencingRelapseRateState, siteLevelBenchmarkingActions } from '../state'
import { PatientsExperiencingRelapseComponent } from './PatientsExperiencingRelapseComponent'

export function createPatientsExperiencingRelapseComponent<
    TState extends { patientsexperiencingrelapse: PatientsExperiencingRelapseRateState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(PatientsExperiencingRelapseComponent, {
            namespace: 'clinicalmanagement',
            dataFetchAction: 'getPatientsExperiencingRelapseRate'
        }),
        (state: TState) => {
            return { ...state.patientsexperiencingrelapse, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type PatientsExperiencingRelapseRate = ReturnType<typeof createPatientsExperiencingRelapseComponent>
