import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { MSDurationState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { MSDurationComponent } from './MSDurationComponent'

export function createMSDurationComponent<TState extends { msDuration: MSDurationState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(MSDurationComponent, { namespace: 'population', dataFetchAction: 'getMSDuration' }),
        (state: TState) => {
            return { ...state.msDuration, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type MSDuration = ReturnType<typeof createMSDurationComponent>
