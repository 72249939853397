import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { EnrollmentChartState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { EnrollmentChartComponent } from './EnrollmentChartComponent'

export function createEnrollmentChartComponent<TState extends { enrollmentChart: EnrollmentChartState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(EnrollmentChartComponent, { namespace: 'clinicalsiteperformance', dataFetchAction: 'getEnrollmentChart' }),
        (state: TState) => {
            return { ...state.enrollmentChart, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type EnrollmentChart = ReturnType<typeof createEnrollmentChartComponent>
