import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { PatientsExperiencingRelapseAPI, SiteLevelBenchmarkingService /*, SiteLevelScoresAPI*/ } from '../service'
import { PatientsExperiencingRelapseRateState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getPatientsExperiencingRelapse = (state: { patientsexperiencingrelapse: PatientsExperiencingRelapseRateState }) =>
    state.patientsexperiencingrelapse

export function createPatientsExperiencingRelapseSaga(
    siteLevelBenchmarkingService: SiteLevelBenchmarkingService,
    getToken: () => string | undefined
) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getPatientsExperiencingRelapseRate>) {
        try {
            const patientsexperiencingrelapse: PatientsExperiencingRelapseRateState = yield select(getPatientsExperiencingRelapse)
            if (
                patientsexperiencingrelapse.lastDateRange !== action.payload.dateRange ||
                patientsexperiencingrelapse.lastOrgId !== action.payload.orgId
            ) {
                yield put(siteLevelBenchmarkingActions.setPatientsExperiencingRelapseRateIsLoading(true))
                const result: PatientsExperiencingRelapseAPI.Responses = yield call(
                    siteLevelBenchmarkingService.patientsExperiencingRelapseAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === PatientsExperiencingRelapseAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setPatientsExperiencingRelapseRate(result.body.response))
                    yield put(
                        siteLevelBenchmarkingActions.setPatientsExperiencingRelapseRateLastQuery(action.payload.dateRange, action.payload.orgId)
                    )
                } else if (result.type === PatientsExperiencingRelapseAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === PatientsExperiencingRelapseAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in PatientsExperiencingRelapseAPI'))
                } else if (result.type === PatientsExperiencingRelapseAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in PatientsExperiencingRelapseSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setPatientsExperiencingRelapseRateIsLoading(false))
        }
    }
}

export type SiteLevelScores = ReturnType<typeof createPatientsExperiencingRelapseSaga>
