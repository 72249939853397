import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, RelapseHistoryAndRateState, siteLevelBenchmarkingActions } from '../state'
import { RelapseHistoryAndRateComponent } from './RelapseHistoryAndRateComponent'

export function createRelapseHistoryAndRateComponent<
    TState extends { relapseHistoryAndRate: RelapseHistoryAndRateState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(RelapseHistoryAndRateComponent, { namespace: 'clinicalmanagement', dataFetchAction: 'getRelapseHistoryAndRate' }),
        (state: TState) => {
            return { ...state.relapseHistoryAndRate, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type RelapseHistoryAndRate = ReturnType<typeof createRelapseHistoryAndRateComponent>
