import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, RaceState, siteLevelBenchmarkingActions } from '../state'
import { RaceComponent } from './RaceComponent'

export function createRaceComponent<TState extends { race: RaceState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(RaceComponent, { namespace: 'query', dataFetchAction: 'getRaceChart' }),
        (state: TState) => {
            return { ...state.race, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type race = ReturnType<typeof createRaceComponent>
