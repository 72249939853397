import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { ConfigState } from '../../shared/config'
import { ClinicalAssessmentState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { ClinicalAssessmentsComponent } from './ClinicalAssessmentsComponent'

export function createClinicalAssessmentComponent<
    TState extends { coAssessment: ClinicalAssessmentState; organizations: OrganizationsState; config: ConfigState }
>() {
    return connectRedux(
        withRouter(ClinicalAssessmentsComponent, { namespace: 'clinicaloutcomes', dataFetchAction: 'getCOAssessmentCharts' }),
        (state: TState) => {
            return { ...state.coAssessment, ...state.organizations, ...state.config }
        },
        siteLevelBenchmarkingActions
    )
}

export type ClinicalAssessment = ReturnType<typeof createClinicalAssessmentComponent>
