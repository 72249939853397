import { Box } from '@mui/system'
import { ClinicalOutcomesComponent } from '../../module/components/ClinicalOutcomesComponent'

export function ClinicalOutcomesPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <ClinicalOutcomesComponent />
        </Box>
    )
}
