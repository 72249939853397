import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { DMTBreakdownByRouteAPI, SiteLevelBenchmarkingService } from '../service'
import { DMTBreakdownByRouteState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getDMTBreakdownByRouteChart = (state: { dmtBreakdownByRoute: DMTBreakdownByRouteState }) => state.dmtBreakdownByRoute

export function createDMTBreakdownByRouteChartSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getDMTBreakdownByRouteChart>) {
        try {
            const dmtBreakdown: DMTBreakdownByRouteState = yield select(getDMTBreakdownByRouteChart)
            if (dmtBreakdown.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setDMTBreakdownByRouteChartIsLoading(true))
                const result: DMTBreakdownByRouteAPI.Responses = yield call(
                    siteLevelBenchmarkingService.dmtBreakdownByRouteAPI,
                    getToken(),
                    action.payload.orgId
                )
                if (result.type === DMTBreakdownByRouteAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setDMTBreakdownByRouteChart(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setDMTBreakdownByRouteChartLastQuery(action.payload.orgId))
                } else if (result.type === DMTBreakdownByRouteAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === DMTBreakdownByRouteAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in DMTBreakdownByRouteAPI'))
                } else if (result.type === DMTBreakdownByRouteAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in DMTBreakdownByRouteChartSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setDMTBreakdownByRouteChartIsLoading(false))
        }
    }
}

export type DMTBreakdownByRouteChartSaga = ReturnType<typeof createDMTBreakdownByRouteChartSaga>
