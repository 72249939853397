import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { keycloakActions } from '../../shared/authentication'
import { OranizationsAPI, SiteLevelBenchmarkingService } from '../service'
import { siteLevelBenchmarkingActions } from '../state'

export function createGetOrganizationsSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getOrganizations>) {
        try {
            yield put(siteLevelBenchmarkingActions.setOrganizationIsLoading(true))
            const result: OranizationsAPI.Responses = yield call(siteLevelBenchmarkingService.oranizationsAPI, getToken())
            if (result.type === OranizationsAPI.SUCCESS) {
                yield put(siteLevelBenchmarkingActions.setOrganizations(result.body.response.orgs))
            } else if (result.type === OranizationsAPI.UNAUTHORIZED) {
                yield put(keycloakActions.sessionExpired())
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setOrganizationIsLoading(false))
        }
    }
}

export type SiteLevelScores = ReturnType<typeof createGetOrganizationsSaga>
