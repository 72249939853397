/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { Alert, AlertTitle, CircularProgress, styled } from '@mui/material'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { cloneDeep, isNull } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import Plot from 'react-plotly.js'
import { MSDurationState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { SiteLegendComponent } from './SiteLegendComponent'
import { plotLayout } from './plot-layout'

export type MSDurationComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    MSDurationState & OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

const SyledLabelBox = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4c4c4c;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
`

const baseLineLabel = (std_dev: number) => (
    <Box>
        <SyledLabelBox>
            <Trans id='Mean duration'>Mean duration</Trans>
        </SyledLabelBox>
        <SyledLabelBox>
            <span>
                {isNull(std_dev) ? t`N/A` : std_dev.toFixed(1)} {t`Std. deviation`}
            </span>
        </SyledLabelBox>
    </Box>
)

const StyledDataPointBox = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

const StyledMyOrgDataPoint = styled(Box)`
    height: 41px;
    color: #8bb8e8;
    font-family: Arial;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
`

const StyledAllOrgsDataPoint = styled(Box)`
    height: 41px;
    color: #aaaeb0;
    font-family: Arial;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
`

const StyledNotEnoughDataMyOrg = styled(StyledMyOrgDataPoint)`
    font-size: 16px;
`

const StyledNotEnoughDataAllOrgs = styled(StyledAllOrgsDataPoint)`
    font-size: 16px;
`

export const MSDurationComponent: FunctionComponent<MSDurationComponentProps> = ({ state, routing, actions }) => {
    useEffect(() => {
        actions.getMSDuration({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    const renderCharts = () => {
        if (state.data === undefined) {
            return (
                <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                    {state.isLoading && <CircularProgress />}
                    {!state.isLoading && (
                        <Alert severity='error' sx={{ width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            Error loading Patient Demographics at Baseline
                        </Alert>
                    )}
                </Box>
            )
        }

        const { my_org_mean_duration, all_orgs_mean_duration, plots } = state.data

        return (
            <>
                <SiteLegendComponent />
                <Box width='100%' display='flex' flexDirection='row'>
                    <Box display='flex' flexDirection='column' width='100%' justifyContent='space-around' flexShrink={2}>
                        <StyledDataPointBox>
                            {my_org_mean_duration.mean ? (
                                <StyledMyOrgDataPoint>{my_org_mean_duration.mean.toFixed(2)} yrs</StyledMyOrgDataPoint>
                            ) : (
                                <StyledNotEnoughDataMyOrg>Not Enough Data</StyledNotEnoughDataMyOrg>
                            )}
                            {baseLineLabel(my_org_mean_duration.std_dev)}
                        </StyledDataPointBox>
                        <StyledDataPointBox>
                            {all_orgs_mean_duration.mean ? (
                                <StyledAllOrgsDataPoint>{all_orgs_mean_duration.mean.toFixed(2)} yrs</StyledAllOrgsDataPoint>
                            ) : (
                                <StyledNotEnoughDataAllOrgs>Not Enough Data</StyledNotEnoughDataAllOrgs>
                            )}
                            {baseLineLabel(all_orgs_mean_duration.std_dev)}
                        </StyledDataPointBox>
                    </Box>
                    <Plot
                        data={cloneDeep(plots['data'])}
                        layout={{ ...cloneDeep(plots['layout']), ...plotLayout }}
                        style={{ width: '100%', height: '100%', opacity: state.isLoading ? 0.4 : 1 }}
                        config={{
                            modeBarButtonsToRemove: [
                                'toImage',
                                'zoom2d',
                                'pan2d',
                                'select2d',
                                'lasso2d',
                                'zoomIn2d',
                                'zoomOut2d',
                                'autoScale2d',
                                'resetScale2d'
                            ],
                            displaylogo: false,
                            staticPlot: state.isLoading
                        }}
                        useResizeHandler={true}
                    />
                </Box>
            </>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px'
            }}
        >
            <h3>
                <Trans id='Duration Since MS Diagnosis'>Duration Since MS Diagnosis</Trans>
            </h3>
            {renderCharts()}
        </Box>
    )
}
