import { HcpApi } from '../../api/hcp-fetch'
import * as PatientsExperiencingRelapseAPI from './patients-experiencing-relapse'
import * as PatientsTreatedWithDmtAPI from './patients-treated-with-dmt'
import * as PatientsExperiencingFallsAPI from './patients-experiencing-falls'
import * as AveragePROScoresAPI from './average-pro-scores'
import * as EnrollmentChartAPI from './enrollment-chart'
import * as OranizationsAPI from './get-organizations'
import * as DMTBreakdownByRouteAPI from './dmt-breakdown-by-route'
import * as DMTBreakdownByMedicationAPI from './dmt-breakdown-by-medication'
import * as RelapseHistoryAndRateAPI from './relapse-history-and-rate'
import * as HospitalizationsRateAPI from './hospitilizations-rate'
import * as ERUtilizationsRateAPI from './er-utilizations-rate'
import * as COPatientPercentageAPI from './co-patient-percentage'
import * as COMeanChangeAPI from './co-mean-change'
import * as PatientBaselineDemographicsAPI from './patient-baseline-demographics'
import * as InsuranceAPI from './insurance'
import * as EmploymentAPI from './employment'
import * as RaceAPI from './race'
import * as EthnicityAPI from './ethnicity'
import * as MSDurationAPI from './ms-duration'
import * as DemographicsOverTimeAPI from './patient-demographics-over-time'

export const createSiteLevelBenchmarkingService = (hcpApi: HcpApi) => {
    return {
        patientsExperiencingRelapseAPI: PatientsExperiencingRelapseAPI.create(hcpApi),
        patientsTreatedWithDmtAPI: PatientsTreatedWithDmtAPI.create(hcpApi),
        patientsExperiencingFallsAPI: PatientsExperiencingFallsAPI.create(hcpApi),
        averagePROScoresAPI: AveragePROScoresAPI.create(hcpApi),
        enollmentChartAPI: EnrollmentChartAPI.create(hcpApi),
        oranizationsAPI: OranizationsAPI.create(hcpApi),
        dmtBreakdownByRouteAPI: DMTBreakdownByRouteAPI.create(hcpApi),
        dmtBreakdownByMedicationAPI: DMTBreakdownByMedicationAPI.create(hcpApi),
        employmentAPI: EmploymentAPI.create(hcpApi),
        insuranceAPI: InsuranceAPI.create(hcpApi),
        relapseHistoryAndRateApi: RelapseHistoryAndRateAPI.create(hcpApi),
        hospitalizationsRateAPI: HospitalizationsRateAPI.create(hcpApi),
        erUtilizationsRateAPI: ERUtilizationsRateAPI.create(hcpApi),
        raceAPI: RaceAPI.create(hcpApi),
        ethnicityAPI: EthnicityAPI.create(hcpApi),
        coPatientPercentageAPI: COPatientPercentageAPI.create(hcpApi),
        coMeanChangeAPI: COMeanChangeAPI.create(hcpApi),
        ptBaselineDemographicsAPI: PatientBaselineDemographicsAPI.create(hcpApi),
        msDurationAPI: MSDurationAPI.create(hcpApi),
        demographicsOverTimeAPI: DemographicsOverTimeAPI.create(hcpApi)
    }
}

export {
    PatientsExperiencingRelapseAPI,
    PatientsTreatedWithDmtAPI,
    AveragePROScoresAPI,
    PatientsExperiencingFallsAPI,
    EnrollmentChartAPI,
    OranizationsAPI,
    DMTBreakdownByRouteAPI,
    DMTBreakdownByMedicationAPI,
    EmploymentAPI,
    InsuranceAPI,
    RelapseHistoryAndRateAPI,
    HospitalizationsRateAPI,
    ERUtilizationsRateAPI,
    RaceAPI,
    EthnicityAPI,
    COPatientPercentageAPI,
    COMeanChangeAPI,
    PatientBaselineDemographicsAPI,
    MSDurationAPI,
    DemographicsOverTimeAPI
}

export type SiteLevelBenchmarkingService = ReturnType<typeof createSiteLevelBenchmarkingService>
