import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { DMTBreakdownByMedicationAPI, SiteLevelBenchmarkingService } from '../service'
import { DMTBreakdownByMedicationState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getDMTBreakdownByMedicationChart = (state: { dmtBreakdownByMedication: DMTBreakdownByMedicationState }) => state.dmtBreakdownByMedication

export function createDMTBreakdownByMedicationChartSaga(
    siteLevelBenchmarkingService: SiteLevelBenchmarkingService,
    getToken: () => string | undefined
) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getDMTBreakdownByMedicationChart>) {
        try {
            const dmtBreakdown: DMTBreakdownByMedicationState = yield select(getDMTBreakdownByMedicationChart)
            if (dmtBreakdown.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setDMTBreakdownByMedicationChartIsLoading(true))
                const result: DMTBreakdownByMedicationAPI.Responses = yield call(
                    siteLevelBenchmarkingService.dmtBreakdownByMedicationAPI,
                    getToken(),
                    action.payload.orgId
                )
                if (result.type === DMTBreakdownByMedicationAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setDMTBreakdownByMedicationChart(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setDMTBreakdownByMedicationChartLastQuery(action.payload.orgId))
                } else if (result.type === DMTBreakdownByMedicationAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === DMTBreakdownByMedicationAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in DMTBreakdownByMedicationAPI'))
                } else if (result.type === DMTBreakdownByMedicationAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in DMTBreakdownByMedicationChartSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setDMTBreakdownByMedicationChartIsLoading(false))
        }
    }
}

export type DMTBreakdownByMedicationChartSaga = ReturnType<typeof createDMTBreakdownByMedicationChartSaga>
