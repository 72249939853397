import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { DMTBreakdownByMedicationState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { DMTBreakdownByMedicationComponent } from './DMTBreakdownByMedicationComponent'

export function createDMTBreakdownByMedicationComponent<
    TState extends { dmtBreakdownByMedication: DMTBreakdownByMedicationState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(DMTBreakdownByMedicationComponent, {
            namespace: 'population',
            dataFetchAction: 'getDMTBreakdownByMedicationChart'
        }),
        (state: TState) => {
            return { ...state.dmtBreakdownByMedication, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type DMTBreakdownByMedication = ReturnType<typeof createDMTBreakdownByMedicationComponent>
