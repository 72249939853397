import { withConfig } from '@om1/platform-utils'
import { AuthClientInitOptions } from '@react-keycloak/core/lib/types'
/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ReactKeycloakProvider } from '@react-keycloak/web'
import * as Keycloak from 'keycloak-js'
import * as React from 'react'
import { KeycloakConfigInterface, keycloakConfig } from './keycloak-config'

interface KeycloakProviderComponentProps {
    children: JSX.Element
    config: KeycloakConfigInterface
    tokenActions: (tokens: { idToken?: string | undefined; refreshToken?: string | undefined; token?: string | undefined }) => void
}

export const KeycloakProviderComponent: React.FunctionComponent<KeycloakProviderComponentProps> = ({
    children,
    config,
    tokenActions
}: {
    children: JSX.Element
    config: KeycloakConfigInterface
    tokenActions: (tokens: { idToken?: string | undefined; refreshToken?: string | undefined; token?: string | undefined }) => void
}) => {
    let { keycloakRealm, keycloakUrl, keycloakClientId } = config
    const keycloak = Keycloak.default({
        realm: keycloakRealm,
        url: keycloakUrl,
        clientId: keycloakClientId
    })
    const initOptions: AuthClientInitOptions = {
        onLoad: 'login-required',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin} + /silent-check-sso.html`
    }
    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions} onTokens={tokenActions} LoadingComponent={<></>}>
            {children}
        </ReactKeycloakProvider>
    )
}

export const KeycloakProvider = withConfig<KeycloakProviderComponentProps>(KeycloakProviderComponent, keycloakConfig)
