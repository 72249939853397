/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/react'
import { Alert, AlertTitle, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { cloneDeep } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import Plot from 'react-plotly.js'
import { ChartQueryParams, DateRangeParam, OrganizationsState, PatientsExperiencingFallsState, siteLevelBenchmarkingActions } from '../state'
import { DateRangeSelectorComponent } from './DateRangeSelectorComponent'
import { plotLayout } from './plot-layout'

export type PatientsExperiencingFallsComponentProps = RoutedFrameworkComponentProps<
    ChartQueryParams,
    {},
    PatientsExperiencingFallsState & OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

export const PatientsExperiencingFallsComponent: FunctionComponent<PatientsExperiencingFallsComponentProps> = ({ state, routing, actions }) => {
    useEffect(() => {
        actions.getPatientsExperiencingFalls({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px'
            }}
        >
            <h3>
                <Trans id='% Patients Experiencing Falls'>% Patients Experiencing Falls</Trans>
            </h3>
            <DateRangeSelectorComponent
                isLoading={state.isLoading}
                handleChange={(dateRange: DateRangeParam) => {
                    routing.updateQuery({
                        ...routing.queryParams,
                        patients_experiencing_falls_rate_chart: {
                            ...routing.queryParams.patients_experiencing_falls_rate_chart,
                            dateRange: dateRange
                        }
                    })
                }}
                selectedValue={routing.queryParams.patients_experiencing_falls_rate_chart?.dateRange || 'all'}
            />
            {state.data !== undefined && (
                <Plot
                    data={cloneDeep(state.data['data'])}
                    layout={{ ...cloneDeep(state.data['layout']), ...plotLayout }}
                    style={{ width: '100%', height: '100%', opacity: state.isLoading ? 0.4 : 1 }}
                    config={{
                        modeBarButtonsToRemove: [
                            'toImage',
                            'zoom2d',
                            'pan2d',
                            'select2d',
                            'lasso2d',
                            'zoomIn2d',
                            'zoomOut2d',
                            'autoScale2d',
                            'resetScale2d'
                        ],
                        displaylogo: false,
                        staticPlot: state.isLoading
                    }}
                    useResizeHandler={true}
                />
            )}{' '}
            {state.data === undefined && (
                <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                    {state.isLoading && <CircularProgress />}
                    {!state.isLoading && (
                        <Alert severity='error' sx={{ width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            Error loading % Patients Experiencing Falls Chart Data
                        </Alert>
                    )}
                </Box>
            )}
        </Box>
    )
}
