import { Box } from '@mui/system'
import { AveragePROScoresState, DateRangeParam } from '../../module/state'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'
import { createEnrollmentChartComponent } from '../../module/components/EnrollmentChart'

export type ClinicalSitePerformacePageState = {
    averageproscores: AveragePROScoresState
}

export interface ClinicalSitePerformacePageQueryParams {
    averagePROsScoresDateRange: DateRangeParam
    pro: string
}

const EnrollmentReportComponent = createEnrollmentChartComponent<SiteLevelBenchmarkingState>()

export function ClinicalSitePerformacePage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <EnrollmentReportComponent />
        </Box>
    )
}
