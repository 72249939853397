import React from 'react'
import ReactDOM from 'react-dom/client'
import { SiteLevelBenchmarking } from './SiteLevelBenchmarking'
import { Config, config } from './SiteLevelBenchmarkingConfig'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Config config={config}>
        <SiteLevelBenchmarking />
    </Config>
)
