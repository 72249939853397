import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { ERUtilizationsRateAPI, SiteLevelBenchmarkingService } from '../service'
import { ERUtilizationsRateState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getERUtilizationsRate = (state: { erUtilizations: ERUtilizationsRateState }) => state.erUtilizations

export function createERUtilizationsRateSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getERUtilizationsRate>) {
        try {
            const erUtilizationsRate: ERUtilizationsRateState = yield select(getERUtilizationsRate)
            if (erUtilizationsRate.lastDateRange !== action.payload.dateRange || erUtilizationsRate.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setERUtilizationRateIsLoading(true))
                const result: ERUtilizationsRateAPI.Responses = yield call(
                    siteLevelBenchmarkingService.erUtilizationsRateAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === ERUtilizationsRateAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setERUtilizationRate(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setERUtilizationRateLastQuery(action.payload.dateRange, action.payload.orgId))
                } else if (result.type === ERUtilizationsRateAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === ERUtilizationsRateAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in ERUtilizationsRateAPI'))
                } else if (result.type === ERUtilizationsRateAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in erUtilizationsRateSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setERUtilizationRateIsLoading(false))
        }
    }
}

export type erUtilizationsRateSaga = ReturnType<typeof createERUtilizationsRateSaga>
