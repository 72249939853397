import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { ERUtilizationsRateState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { ERUtilizationsRateComponent } from './ERUtilizationsRateComponent'

export function createERUtilizationsRateComponent<TState extends { erUtilizations: ERUtilizationsRateState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(ERUtilizationsRateComponent, { namespace: 'clinicalmanagement', dataFetchAction: 'getERUtilizationsRate' }),
        (state: TState) => {
            return { ...state.erUtilizations, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type erUtilizationsRate = ReturnType<typeof createERUtilizationsRateComponent>
