/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Alert, AlertTitle, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { cloneDeep } from 'lodash'
import { FunctionComponent, PropsWithChildren } from 'react'
import Plot from 'react-plotly.js'
import { PlotlyResponse } from '../state'
import { SiteLegendComponent } from './SiteLegendComponent'
import { plotLayout } from './plot-layout'

export const ClinicalAssessmentPlotlyChartComponent: FunctionComponent<
    PropsWithChildren<{ errorMessage: string; isLoading?: boolean; data?: PlotlyResponse }>
> = (props) => {
    const { errorMessage, isLoading, data, children } = props

    if (data === undefined) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                {isLoading && <CircularProgress />}
                {!isLoading && (
                    <Alert severity='error' sx={{ width: '100%' }}>
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                )}
            </Box>
        )
    }

    return (
        <>
            {children}
            <SiteLegendComponent />
            <Plot
                data={cloneDeep(data['data'])}
                layout={{ ...cloneDeep(data['layout']), ...plotLayout }}
                style={{ width: '100%', height: '100%', opacity: isLoading ? 0.4 : 1 }}
                config={{
                    modeBarButtonsToRemove: [
                        'toImage',
                        'zoom2d',
                        'pan2d',
                        'select2d',
                        'lasso2d',
                        'zoomIn2d',
                        'zoomOut2d',
                        'autoScale2d',
                        'resetScale2d'
                    ],
                    displaylogo: false,
                    staticPlot: isLoading
                }}
                useResizeHandler={true}
            />
        </>
    )
}
