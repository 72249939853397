import { FunctionComponent } from 'react'
import { Box } from '@mui/system'
import CircleIcon from '@mui/icons-material/Circle'
import { styled } from '@mui/material'
import { Trans } from '@lingui/react'

export const SiteLegendComponent: FunctionComponent<any> = () => {
    const LegendLabel = styled(Box)`
        height: 22px;
        color: #7f7f7f;
        font-family: Arial;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 22px;
    `

    return (
        <Box display='flex' justifyContent='end' width='100%' marginRight='40px'>
            <CircleIcon sx={{ color: '#8BB8E8', marginRight: '5px' }} />
            <LegendLabel>
                <Trans id='My Clinical Site'>My Clinical Site</Trans>
            </LegendLabel>
            <Box width='20px' />
            <CircleIcon sx={{ color: '#C1C6C8', marginRight: '5px' }} />
            <LegendLabel>
                <Trans id='All Clinical Sites'>All Clinical Sites</Trans>
            </LegendLabel>{' '}
        </Box>
    )
}
