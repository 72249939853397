import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { keycloakActions } from '../../shared/authentication'
import { AveragePROScoresAPI, SiteLevelBenchmarkingService } from '../service'
import { AveragePROScoresState, BasicChartQueryObject, siteLevelBenchmarkingActions } from '../state'

export const getAveragePROScores = (state: { averageproscores: AveragePROScoresState }) => state.averageproscores

export function createAveragePROScoresSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getAveragePROScores>) {
        try {
            const averageproscores: AveragePROScoresState = yield select(getAveragePROScores)
            if (
                averageproscores.lastDateRange !== action.payload.dateRange ||
                averageproscores.lastPro !== action.payload.pro ||
                averageproscores.lastOrgId !== action.payload.orgId
            ) {
                yield put(siteLevelBenchmarkingActions.setAveragePROScoresIsLoading(true))
                const result: AveragePROScoresAPI.Responses = yield call(
                    siteLevelBenchmarkingService.averagePROScoresAPI,
                    getToken(),
                    action.payload.pro!,
                    action.payload.dateRange!,
                    action.payload.orgId
                )
                if (result.type === AveragePROScoresAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setAveragePROScores(result.body.response))
                    yield put(
                        siteLevelBenchmarkingActions.setAveragePROScoresLastQuery(
                            (action.payload as BasicChartQueryObject).dateRange,
                            action.payload.pro,
                            action.payload.orgId
                        )
                    )
                } else if (result.type === AveragePROScoresAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === AveragePROScoresAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in AveragePROScoresAPI'))
                } else if (result.type === AveragePROScoresAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in AveragePROScoresSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setAveragePROScoresIsLoading(false))
        }
    }
}

export type AveragePROScoresSaga = ReturnType<typeof createAveragePROScoresSaga>
