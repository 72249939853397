import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { withRouter } from '@om1/platform-routing'
import { ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps, connectRedux } from '@om1/platform-utils'
import { FunctionComponent } from 'react'
import { Redirect, Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom'
import { createAveragePROScoresComponent } from '../../module/components/AveragePROScores'
import { createDemographicsOverTimeComponent } from '../../module/components/DemographicsOverTime'
import { createEmploymentAndInsuranceComponent } from '../../module/components/EmploymentAndInsurance'
import { createEnrollmentChartComponent } from '../../module/components/EnrollmentChart'
import { createHospitalizationsRateComponent } from '../../module/components/HospitilizationsRate'
import { createPatientBaselineDemographicsComponent } from '../../module/components/PatientBaselineDemographics'
import { createPatientsExperiencingFallsComponent } from '../../module/components/PatientsExperiencingFalls'
import { createPatientsExperiencingRelapseComponent } from '../../module/components/PatientsExperiencingRelapseRate'
import { createPatientsTreatedWithDmtComponent } from '../../module/components/PatientsTreatedWithDmt'
import { createRelapseHistoryAndRateComponent } from '../../module/components/RelapseHistoryAndRate'
import { ChartKeys, OrganizationsState, siteLevelBenchmarkingActions } from '../../module/state'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'
import { tabs } from './SiteLevelBenchmarkingLandingPage'

export function createLandingPageContentContainerComponent<TState extends { organizations: OrganizationsState }>() {
    return connectRedux(withRouter(LandingPageContentContainerComponent), (state: TState) => state.organizations, siteLevelBenchmarkingActions)
}

export type LandingPageContentContainer = ReturnType<typeof createLandingPageContentContainerComponent>

export type LandingPageContentContainerComponentProps = RoutedFrameworkComponentProps<
    {},
    { orgId: string },
    OrganizationsState,
    typeof siteLevelBenchmarkingActions,
    {}
>

// Direct chart access
const charts: { name: string; chart: FunctionComponent }[] = [
    { name: ChartKeys.enrollment_chart, chart: createEnrollmentChartComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patient_demographics_at_baseline, chart: createPatientBaselineDemographicsComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patient_demographics_ms_duration, chart: createDemographicsOverTimeComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patients_experiencing_relapse_chart, chart: createPatientsExperiencingRelapseComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patients_experiencing_falls_rate_chart, chart: createPatientsExperiencingFallsComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patients_treated_with_dmt_rate_chart, chart: createPatientsTreatedWithDmtComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.average_pro_scores, chart: createAveragePROScoresComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.relapse_history_and_rate, chart: createRelapseHistoryAndRateComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.hospitalization_rate, chart: createHospitalizationsRateComponent<SiteLevelBenchmarkingState>() },
    { name: ChartKeys.patient_demographics_employment_and_insurance, chart: createEmploymentAndInsuranceComponent<SiteLevelBenchmarkingState>() }
]

export const LandingPageContentContainerComponent: FunctionComponent<LandingPageContentContainerComponentProps> = ({ state, routing, actions }) => {
    const match = useRouteMatch()

    const { path: matchPathValue, url: matchUrlValue } = match

    const chartRoutes: {
        route: RouteProps<
            string,
            {
                [x: string]: string | undefined
            }
        >
        url: string
        chart: FunctionComponent<{}>
        name: string
    }[] = charts.map((chartRecord: { name: string; chart: FunctionComponent }) => {
        const route: RouteProps = { path: `${matchPathValue}${chartRecord.name}` }
        const url = `${matchUrlValue}/${chartRecord.name}`
        return { route, url, chart: chartRecord.chart, name: chartRecord.name }
    })

    if (state.orgs && state.orgs.length > 0) {
        return (
            <Switch>
                {chartRoutes.map(
                    (chartRoute: {
                        route: RouteProps<
                            string,
                            {
                                [x: string]: string | undefined
                            }
                        >
                        url: string
                        chart: FunctionComponent<{}>
                        name: string
                    }) => {
                        return (
                            <Route key={chartRoute.name} {...chartRoute.route} exact>
                                <ContentPadding padX padY>
                                    <chartRoute.chart />
                                </ContentPadding>
                            </Route>
                        )
                    }
                )}
                {tabs.map((tab) => (
                    <Route key={`route-${tab.localpath}`} path={`/:orgId/${tab.localpath}`} exact>
                        <ContentPadding padX padY>
                            {tab.component}
                        </ContentPadding>
                    </Route>
                ))}
                <Redirect key='baseRedirect' to={`/${routing.pathParams.orgId}/clinicalsiteperformance`} />
            </Switch>
        )
    } else {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='50vh'>
                <CircularProgress />
            </Box>
        )
    }
}
