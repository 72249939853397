import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { EmploymentAndInsuranceState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { EmploymentAndInsuranceComponent } from './EmploymentAndInsuranceComponent'

export function createEmploymentAndInsuranceComponent<
    TState extends { employmentAndInsurance: EmploymentAndInsuranceState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(EmploymentAndInsuranceComponent, {
            namespace: 'population',
            dataFetchAction: 'getEmploymentAndInsuranceChart'
        }),
        (state: TState) => {
            return { ...state.employmentAndInsurance, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type EmploymentAndInsurance = ReturnType<typeof createEmploymentAndInsuranceComponent>
