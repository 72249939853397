import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { PatientsTreatedWithDmtAPI, SiteLevelBenchmarkingService } from '../service'
import { PatientsTreatedWithDmtState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getPatientsTreatedWithDmt = (state: { patientstreatedwithdmt: PatientsTreatedWithDmtState }) => state.patientstreatedwithdmt

export function createPatientsTreatedWithDmtSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getPatientsTreatedWithDmt>) {
        try {
            const patientstreatedwithdmt: PatientsTreatedWithDmtState = yield select(getPatientsTreatedWithDmt)
            if (patientstreatedwithdmt.lastDateRange !== action.payload.dateRange || patientstreatedwithdmt.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setPatientsTreatedWithDmtIsLoading(true))
                const result: PatientsTreatedWithDmtAPI.Responses = yield call(
                    siteLevelBenchmarkingService.patientsTreatedWithDmtAPI,
                    getToken(),
                    action.payload.dateRange,
                    action.payload.orgId
                )
                if (result.type === PatientsTreatedWithDmtAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setPatientsTreatedWithDmt(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setPatientTreatmentWithDmtLastQuery(action.payload.dateRange, action.payload.orgId))
                } else if (result.type === PatientsTreatedWithDmtAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === PatientsTreatedWithDmtAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in PatientsTreatedWithDmtAPI'))
                } else if (result.type === PatientsTreatedWithDmtAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in PatientsTreatedWithDmtSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setPatientsTreatedWithDmtIsLoading(false))
        }
    }
}

export type SiteLevelScores = ReturnType<typeof createPatientsTreatedWithDmtSaga>
