import { Box } from '@mui/system'
import { createAveragePROScoresComponent } from '../../module/components/AveragePROScores'
import { AveragePROScoresState, DateRangeParam } from '../../module/state'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'
import { Trans } from '@lingui/macro'

export type PROsPageState = {
    averageproscores: AveragePROScoresState
}

export interface PROsPageQueryParams {
    averagePROsScoresDateRange: DateRangeParam
    pro: string
}

const AveragePROScoresComponent = createAveragePROScoresComponent<SiteLevelBenchmarkingState>()

export function PROsPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <AveragePROScoresComponent />
            <Box sx={{ paddingBottom: '5px' }}>
                <Trans>
                    <b>NOTE:</b> Scores displayed represent the average score for my clinical site and all clinical sites
                </Trans>
            </Box>
        </Box>
    )
}
