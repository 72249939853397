/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/react'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect } from 'react'
import { DEFAULT_ASSESSMENT, HCPAssessmentType } from '../../api/hcp-assessments'
import { ConfigState } from '../../shared/config'
import { ChartQueryParams, ClinicalAssessmentState, ExtendedChartQueryObject, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { ClinicalAssessmentPlotlyChartComponent } from './ClinicalAssessmentPlotlyChartComponent'
import { ClinicalAssessmentSelectorComponent } from './ClinicalAssessmentSelectorComponent'

export type ClinicalAssessmentComponentProps = RoutedFrameworkComponentProps<
    ChartQueryParams,
    {},
    ClinicalAssessmentState & OrganizationsState & ConfigState,
    typeof siteLevelBenchmarkingActions,
    {}
>

export const ClinicalAssessmentsComponent: FunctionComponent<ClinicalAssessmentComponentProps> = ({ state, routing, actions }) => {
    useEffect(() => {
        actions.getCOAssessmentCharts({ query: routing.queryParams, path: routing.pathParams })
    }, [state.selectedOrgId, routing.queryParams, routing.pathParams, actions])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px'
            }}
        >
            <ClinicalAssessmentSelectorComponent
                handleChange={(assessment: HCPAssessmentType) => {
                    routing.updateQuery({
                        ...routing.queryParams,
                        clinical_outcomes_assessment: {
                            ...(routing.queryParams.clinical_outcomes_assessment as ExtendedChartQueryObject),
                            assessment: assessment
                        }
                    })
                }}
                selectedAssessment={
                    ((routing.queryParams.clinical_outcomes_assessment as ExtendedChartQueryObject)?.assessment as HCPAssessmentType) ||
                    (DEFAULT_ASSESSMENT as HCPAssessmentType)
                }
                isLoading={state.isLoading}
            />
            <ClinicalAssessmentPlotlyChartComponent
                errorMessage='Error loading Clinical Outcomes Patient Percentage Chart Data'
                isLoading={state.isLoading}
                data={state.patientPecent}
            />
            {!state.hideCOMeanChange && (
                <ClinicalAssessmentPlotlyChartComponent
                    errorMessage='Error loading Clinical Outcomes Mean Change Chart Data'
                    isLoading={state.isLoading}
                    data={state.meanChange}
                >
                    <h3 style={{ opacity: state.isLoading ? 0.4 : 1 }}>
                        <Trans id='Mean Change from Baseline'>Mean Change from Baseline</Trans>: {state.lastAssessment}
                    </h3>
                </ClinicalAssessmentPlotlyChartComponent>
            )}
        </Box>
    )
}
