import { t, Trans } from '@lingui/macro'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Menu, MenuItem, Select } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import * as React from 'react'
import { FunctionComponent, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Org, OrganizationsState, siteLevelBenchmarkingActions } from './module/state'
import { createSiteLevelBenchmarkingLandingPageComponent } from './pages/SiteLevelBenchmarkingLandingPage/SiteLevelBenchmarkingLandingPage'
import { UnderConstructionPage } from './pages/UnderConstructionPage/UnderConstructionPage'
import { keycloakActions, KeycloakState } from './shared/authentication'
import { configActions, ConfigState } from './shared/config'
import { SiteLevelBenchmarkingState } from './site-level-benchmarking-state'

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<MuiAppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    ...{
        width: `calc(100% - ${0}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        backgroundColor: 'red'
    }
}))

export type PageContainerComponentProps = RoutedFrameworkComponentProps<
    {},
    { orgId: string },
    OrganizationsState & KeycloakState & ConfigState,
    typeof siteLevelBenchmarkingActions & typeof keycloakActions & typeof configActions,
    React.PropsWithChildren<{
        logo?: React.ReactElement
        username?: string
        logoutFunction?: () => void
        loginFunction?: () => void
    }>
>

const SiteLevelBenchmarkingLandingPageComponent = createSiteLevelBenchmarkingLandingPageComponent<SiteLevelBenchmarkingState>()

export const UserMenu: FunctionComponent<{ username?: string; logoutFunction?: () => void }> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    p: 0,
                    borderRadius: '0px',
                    marginLeft: '30px',
                    height: '30px',
                    transition: 'opacity 2s ease',
                    color: '#FFFFFF',
                    fontFamily: 'Metropolis',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    letterSpacing: '0',
                    lineHeight: '16px'
                }}
            >
                {props.username}
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem key={t`Logout`} onClick={props.logoutFunction}>
                    <Trans>Logout</Trans>
                </MenuItem>
            </Menu>
        </div>
    )
}

export const PageContainerComponent: React.FunctionComponent<PageContainerComponentProps> = ({ props, state, actions }) => {
    const history = useHistory()

    const location = useLocation()

    const [orgValue, setOrgValue] = useState('')

    const handleOrgValueChange = (e) => {
        history.push(`/${e.target.value}`)
        setOrgValue(e.target.value)
    }

    const underConstruction = state.siteUnderConstruction

    useEffect(() => {
        if (!state.isOrgsLoading && state.orgs && state.orgs.length === 1) {
            history.push(`/${state.orgs[0].id}`)
        }
    }, [state.isOrgsLoading, state.orgs, history])

    if (location) {
        return (
            <Box sx={{ display: 'flex', flex: 1, minWidth: 0 }}>
                <Dialog
                    fullWidth
                    onClose={() => {}}
                    open={state.isSessionExpired ? state.isSessionExpired : false}
                    maxWidth='xs'
                    sx={{
                        backdropFilter: 'blur(5px)'
                    }}
                >
                    <DialogTitle>
                        <Trans>Your session has expired.</Trans>
                    </DialogTitle>
                    <DialogContent>
                        <Trans>Click below to be redirected to login.</Trans>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                actions.logout()
                            }}
                        >
                            <Trans>Login</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
                {!state.isOrgsLoading && state.orgs && state.orgs.length > 0 && !underConstruction && (
                    <Switch>
                        <Route key={`route-org-id`} path={`/:orgId/:chartName?`}>
                            <SiteLevelBenchmarkingLandingPageComponent
                                logo={props.logo}
                                username={props.username}
                                logoutFunction={props.logoutFunction}
                            />
                        </Route>
                        <Route key={`route-no-org-id`} path={`/`} exact>
                            <AppBar position='fixed'>
                                <Toolbar variant='dense' sx={{ backgroundColor: '#43454A' }}>
                                    {props.logo}
                                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} />
                                    {!underConstruction && <UserMenu username={props.username} logoutFunction={props.logoutFunction} />}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            paddingLeft: '10px'
                                        }}
                                    ></Box>
                                </Toolbar>
                            </AppBar>
                            <Box
                                paddingTop={7}
                                component='main'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    minWidth: 0
                                }}
                            >
                                <ContentContainerStyle>
                                    <ContentPadding padX padY>
                                        <Box
                                            sx={{
                                                height: '40px',
                                                color: '#55555A',
                                                fontFamily: 'Arial',
                                                fontSize: '28px',
                                                letterSpacing: '0',
                                                lineHeight: '25px',
                                                textAlign: 'center',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <Trans>Site-Level Benchmark Reports</Trans>
                                        </Box>
                                    </ContentPadding>
                                    <Divider sx={{ marginTop: '-10px' }} />
                                    <ContentPadding padX padY>
                                        <Select
                                            displayEmpty
                                            value={orgValue}
                                            onChange={handleOrgValueChange}
                                            id='org-select'
                                            sx={{ width: '100%', color: orgValue === '' ? 'grey' : 'black' }}
                                            label={t`Select Clinical Site`}
                                        >
                                            {[
                                                <MenuItem key='placeholder' disabled value=''>
                                                    <Trans>Select Clinical Site</Trans>
                                                </MenuItem>
                                            ].concat(
                                                state.orgs.map((org: Org) => (
                                                    <MenuItem key={org.name} value={org.id}>
                                                        {org.name}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </ContentPadding>
                                </ContentContainerStyle>
                            </Box>
                        </Route>
                    </Switch>
                )}
                {underConstruction && <UnderConstructionPage />}
                {state.isOrgsLoading && !underConstruction && (
                    <ContentContainerStyle>
                        <ContentPadding padX padY>
                            <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                                <CircularProgress />
                            </Box>
                        </ContentPadding>
                    </ContentContainerStyle>
                )}
            </Box>
        )
    }
    return null
}
