import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { EmploymentAPI, InsuranceAPI, SiteLevelBenchmarkingService } from '../service'
import { EmploymentAndInsuranceState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getEmploymentAndInsuranceState = (state: { employmentAndInsurance: EmploymentAndInsuranceState }) => state.employmentAndInsurance

export function createEmploymentAndInsurance(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getEmploymentAndInsuranceChart>) {
        try {
            const employmentAndInsurance: EmploymentAndInsuranceState = yield select(getEmploymentAndInsuranceState)
            if (employmentAndInsurance.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setEmploymentAndInsuranceChartIsLoading(true))
                const employmentResult: EmploymentAPI.Responses = yield call(
                    siteLevelBenchmarkingService.employmentAPI,
                    getToken(),
                    action.payload.orgId
                )
                const insuranceResult: InsuranceAPI.Responses = yield call(
                    siteLevelBenchmarkingService.insuranceAPI,
                    getToken(),
                    action.payload.orgId
                )
                if (employmentResult.type === EmploymentAPI.SUCCESS && insuranceResult.type === InsuranceAPI.SUCCESS) {
                    yield put(
                        siteLevelBenchmarkingActions.setEmploymentAndInsuranceChart({
                            employment: employmentResult.body.response,
                            insurance: insuranceResult.body.response
                        })
                    )
                    yield put(siteLevelBenchmarkingActions.setEmploymentAndInsuranceChartLastQuery(action.payload.orgId))
                } else if (employmentResult.type === EmploymentAPI.UNAUTHORIZED || insuranceResult.type === InsuranceAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (employmentResult.type === EmploymentAPI.NOTFOUND || insuranceResult.type === InsuranceAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in EmploymentAndInsuranceAPI'))
                } else if (employmentResult.type === EmploymentAPI.ERROR || insuranceResult.type === InsuranceAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in EmploymentAndInsurance'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setEmploymentAndInsuranceChartIsLoading(false))
        }
    }
}

export type EmploymentAndInsurance = ReturnType<typeof createEmploymentAndInsurance>
