import { notificationActions } from '@om1/platform-notifications'
import { all, call, put, select } from 'redux-saga/effects'
import { HCPAssessmentType } from '../../api/hcp-assessments'
import { keycloakActions } from '../../shared/authentication'
import { COMeanChangeAPI, COPatientPercentageAPI, SiteLevelBenchmarkingService } from '../service'
import { ClinicalAssessmentState, siteLevelBenchmarkingActions } from '../state'

export const getCOAssessment = (state: { coAssessment: ClinicalAssessmentState }) => state.coAssessment

export function createCOAssessmentSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getCOAssessmentCharts>) {
        try {
            const coassessment: ClinicalAssessmentState = yield select(getCOAssessment)

            if (coassessment.lastAssessment !== action.payload.assessment || coassessment.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setCOAssessmentIsLoading(true))
                const [meanChange, patientPercent] = yield all([
                    call(
                        siteLevelBenchmarkingService.coMeanChangeAPI,
                        getToken(),
                        action.payload.assessment as HCPAssessmentType,
                        action.payload.orgId
                    ),
                    call(
                        siteLevelBenchmarkingService.coPatientPercentageAPI,
                        getToken(),
                        action.payload.assessment as HCPAssessmentType,
                        action.payload.orgId
                    )
                ])

                if (meanChange.type === COMeanChangeAPI.SUCCESS && patientPercent.type === COPatientPercentageAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setCOAssessmentCharts(meanChange.body.response, patientPercent.body.response))
                    yield put(
                        siteLevelBenchmarkingActions.setCOAssessmentLastQuery(action.payload.assessment as HCPAssessmentType, action.payload.orgId)
                    )
                } else if (meanChange.type === COMeanChangeAPI.UNAUTHORIZED || patientPercent.type === COPatientPercentageAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                }

                if (meanChange.type === COMeanChangeAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in COMeanChangeAPI'))
                } else if (meanChange.type === COMeanChangeAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in COMeanChangeAPI'))
                }

                if (patientPercent.type === COPatientPercentageAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in COPatientPercentageAPI'))
                } else if (patientPercent.type === COPatientPercentageAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in COPatientPercentageAPI'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setCOAssessmentIsLoading(false))
        }
    }
}

export type COAssessmentSaga = ReturnType<typeof createCOAssessmentSaga>
