import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { ConfigState } from '../../shared/config'
import { DemographicsOverTimeState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { DemographicsOverTimeComponent } from './DemographicsOverTimeComponent'

export function createDemographicsOverTimeComponent<
    TState extends { demographicsOverTime: DemographicsOverTimeState; organizations: OrganizationsState; config: ConfigState }
>() {
    return connectRedux(
        withRouter(DemographicsOverTimeComponent, { namespace: 'population', dataFetchAction: 'getDemographicsOverTime' }),
        (state: TState) => {
            return { ...state.demographicsOverTime, ...state.organizations, ...state.config }
        },
        siteLevelBenchmarkingActions
    )
}

export type ClinicalOutcomesMeanChange = ReturnType<typeof createDemographicsOverTimeComponent>
