import '@fontsource/metropolis' // Defaults to weight 400.
import { AllLocaleData, AllMessages } from '@lingui/core'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { styled } from '@mui/system'
import { createHcpAPI } from './api/hcp-fetch'
import { TrackingProvider } from '@om1/platform-tracking'
import { Footer } from '@om1/platform-ui-kit/src/components/Footer'
import { MainContainer, PageContainer } from '@om1/platform-ui-kit/src/components/Layout'
import { platformTheme } from '@om1/platform-ui-kit/src/theme'
import { withConfig } from '@om1/platform-utils'
import { useKeycloak } from '@react-keycloak/web'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { en, es } from 'make-plural'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { SiteLevelBenchmarkingConfigInterface, config } from './SiteLevelBenchmarkingConfig'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import LogoHorizontal from './shared/assets/OM1_Logo_Horizontal_rev_right.png'
import LanguageProvider from './shared/providers/internationalization/LanguageProvider'
import { LocaleEnum } from './shared/providers/internationalization/language-config'
import {
    SiteLevelBenchmarkingAppStore,
    SiteLevelBenchmarkingState,
    createAppStore,
    createReducer,
    createRootSaga
} from './site-level-benchmarking-state'
import { KeycloakProvider, keycloakActions } from './shared/authentication'
import { createPageContainerComponent } from './PageContainer'
import { siteLevelBenchmarkingActions } from './module/state'

const Logo = styled('img')({
    margin: '0px 32px 0px 32px',
    lineHeight: 0,
    cursor: 'pointer',
    width: '83px',
    height: '21px'
})

export function SiteLevelBenchmarking() {
    // create top level react contexts (router history, saga middleware, redux store etc)
    const history = createBrowserHistory()
    const appReducer = createReducer(history)
    const sagaMiddleware = createSagaMiddleware()
    const store = createAppStore(appReducer, sagaMiddleware, routerMiddleware(history))

    return (
        <Provider store={store}>
            <KeycloakProvider tokenActions={() => {}}>
                <ConnectedRouter history={history}>
                    <SnackbarProvider maxSnack={3}>
                        <TrackingProvider>
                            <ConfiguredApp sagaMiddleware={sagaMiddleware} store={store} />
                        </TrackingProvider>
                    </SnackbarProvider>
                </ConnectedRouter>
            </KeycloakProvider>
        </Provider>
    )
}

const PageContainerComponent = createPageContainerComponent<SiteLevelBenchmarkingState>()

interface ConfiguredAppComponentProps {
    config: SiteLevelBenchmarkingConfigInterface
    sagaMiddleware: SagaMiddleware<object>
    store: SiteLevelBenchmarkingAppStore
}

const ConfiguredAppComponent: React.FunctionComponent<ConfiguredAppComponentProps> = ({
    config,
    sagaMiddleware,
    store
}: ConfiguredAppComponentProps) => {
    let { hcpApiUrl } = config
    // establish contexts
    const { keycloak, initialized } = useKeycloak()
    const { enqueueSnackbar } = useSnackbar()

    // create api service (with platform configured url)
    const hcpApi = createHcpAPI(hcpApiUrl, enqueueSnackbar, keycloak.isTokenExpired)

    // start redux-sagas
    const rootsaga = createRootSaga(keycloak, hcpApi, enqueueSnackbar)

    const [firstLoad, setFirstLoad] = React.useState(true)
    if (rootsaga && firstLoad) {
        sagaMiddleware.run(rootsaga)
        setFirstLoad(false)
    }

    const [username, setUsername] = React.useState<string | undefined>(undefined)

    // manages the keycloak authentication status, issues actions based on changes
    useEffect(() => {
        if (!initialized) {
            return
        }

        const fetchUserInfo = async () => {
            try {
                let user = await keycloak.loadUserProfile()
                setUsername(user.username)
                store.dispatch(keycloakActions.setKeycloakProfile({ ...user }))
                keycloak.token && store.dispatch(keycloakActions.setKeycloakTokens(keycloak.token, keycloak.refreshToken, keycloak.token))
                store.dispatch(siteLevelBenchmarkingActions.getOrganizations())
            } catch (err) {
                keycloak.login()
            }
        }

        if (keycloak.authenticated) {
            fetchUserInfo()
        } else {
            keycloak.login()
        }
    }, [keycloak, initialized, store])

    // establish translations
    const messages: AllMessages = {
        en: enMessages,
        es: esMessages
    }
    const plurals: AllLocaleData = {
        en: { plurals: en },
        es: { plurals: es }
    }

    return username ? (
        <React.StrictMode>
            <LanguageProvider language={LocaleEnum.English} messages={messages} plurals={plurals}>
                <ThemeProvider theme={platformTheme}>
                    <CssBaseline />
                    <MainContainer sx={{ backgroundColor: '#F1F1F1', minWidth: '800px' }}>
                        <PageContainer>
                            <Box
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    border: '1px solid #CCCCCC'
                                }}
                            >
                                <PageContainerComponent
                                    logo={
                                        <>
                                            <Logo
                                                alt='logo'
                                                src={LogoHorizontal}
                                                onClick={() => {
                                                    window.open('https://www.om1.com/')
                                                }}
                                            />
                                        </>
                                    }
                                    username={username}
                                    logoutFunction={() => {
                                        keycloak.logout()
                                    }}
                                />
                            </Box>
                        </PageContainer>
                        <Footer />
                    </MainContainer>
                </ThemeProvider>
            </LanguageProvider>
        </React.StrictMode>
    ) : (
        <></>
    )
}

const ConfiguredApp = withConfig<ConfiguredAppComponentProps>(ConfiguredAppComponent, config)
