import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { EthnicityState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { EthnicityComponent } from './EthnicityComponent'

export function createEthnicityComponent<TState extends { ethnicity: EthnicityState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(EthnicityComponent, { namespace: 'query', dataFetchAction: 'getEthnicityChart' }),
        (state: TState) => {
            return { ...state.ethnicity, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type ethnicity = ReturnType<typeof createEthnicityComponent>
