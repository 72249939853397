import { createResponse } from '@om1/platform-utils'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { SweetPath } from 'sweet-path'
import { keycloakActions } from '../shared/authentication'

/**
 * Makes a request to an hcp api endpoint
 * @param path the path representing the server API to call
 * @param body the jsonifiable request body
 * @param abortSignal used to cancel the request if necessary
 */
export function createHcpAPI(
    baseUrl: string,
    enqueueSnackbar?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
    isTokenExpired?: (minValidity?: number | undefined) => boolean
) {
    function bearer(token: string | undefined) {
        return `Bearer ${token}`
    }

    return async function fetchPlatformAPI(
        path: SweetPath<string>,
        pathParams: {
            [key in string]: string
        },
        method?: string,
        token?: string,
        body?: {},
        abortSignal?: AbortSignal
    ): Promise<[string, number]> {
        if (token && isTokenExpired && isTokenExpired()) {
            keycloakActions.sessionExpired()
        }
        const headers = {
            'Content-Type': 'application/json',
            Authorization: bearer(token)
        }
        const url: URL = new URL(path.insert(pathParams), baseUrl)

        try {
            const response = await fetch(url, {
                signal: abortSignal,
                method: method ? method : 'GET',
                headers: headers,
                ...(body ? { body: JSON.stringify(body) } : {})
            })

            const responseBody = await response.text()
            return [responseBody, response.status]
        } catch (error) {
            if (process.env.NODE_ENV !== 'production' && enqueueSnackbar) {
                // enqueueSnackbar(`${(error as Error).message} ${url}`, {
                //     variant: 'error'
                // })
            }
            return new Promise<[string, number]>((resolve) => {
                resolve([(error as Error).message, 500])
            })
        }
    }
}

export type HcpApi = ReturnType<typeof createHcpAPI>

export const HCP_SUCCESS = 'success'
export const HCP_ERROR = 'error'
export const HCP_UNAUTHORIZED = 'unauthorized'
export const HCP_NOTFOUND = 'notfound'
export const HCP_CONFLICT = 'conflict'
export const HCP_BADREQUEST = 'badrequest'
export const HCP_UNPROCESSABLE = 'unprocessable'

export const hcpError = () => createResponse(HCP_ERROR)
export const hcpSuccess = () => createResponse(HCP_SUCCESS)
export const hcpUnauthorized = () => createResponse(HCP_UNAUTHORIZED)
export const hcpBadRequest = () => createResponse(HCP_BADREQUEST)
export const hcpNotfound = () => createResponse(HCP_NOTFOUND)
