import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { keycloakActions } from '../../shared/authentication'
import { DemographicsOverTimeAPI, SiteLevelBenchmarkingService } from '../service'
import { DemographicsOverTimeState, siteLevelBenchmarkingActions } from '../state'

export const getDemographicsOverTime = (state: { demographicsOverTime: DemographicsOverTimeState }) => state.demographicsOverTime

export function createDemographicsOverTimeSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getDemographicsOverTime>) {
        try {
            const demographicsovertime: DemographicsOverTimeState = yield select(getDemographicsOverTime)
            if (
                demographicsovertime.lastDemographic !== action.payload.demographic ||
                demographicsovertime.lastOrgId !== action.payload.orgId ||
                demographicsovertime.lastDateRange !== action.payload.dateRange
            ) {
                yield put(siteLevelBenchmarkingActions.setDemographicsOverTimeIsLoading(true))
                const result: DemographicsOverTimeAPI.Responses = yield call(
                    siteLevelBenchmarkingService.demographicsOverTimeAPI,
                    getToken(),
                    action.payload.demographic,
                    action.payload.dateRange!,
                    action.payload.orgId
                )
                if (result.type === DemographicsOverTimeAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setDemographicsOverTime(result.body.response))
                    yield put(
                        siteLevelBenchmarkingActions.setDemographicsOverTimeLastQuery(
                            action.payload.demographic,
                            action.payload.dateRange,
                            action.payload.orgId
                        )
                    )
                } else if (result.type === DemographicsOverTimeAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === DemographicsOverTimeAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in DemographicsOverTimeAPI'))
                } else if (result.type === DemographicsOverTimeAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in DemographicsOverTimeAPI'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setDemographicsOverTimeIsLoading(false))
        }
    }
}

export type COMeanChangeSaga = ReturnType<typeof createDemographicsOverTimeSaga>
