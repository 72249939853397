/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Box } from '@mui/system'
import { FunctionComponent } from 'react'
import { Trans } from '@lingui/react'
import { createRaceComponent } from './Race'
import { createEthnicityComponent } from './Ethnicity'

const RaceComponent = createRaceComponent()
const EthnicityComponent = createEthnicityComponent()

export const RaceEthnicityComponent: FunctionComponent<any> = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: '20px',
                transition: 'opacity 2s ease'
            }}
        >
            <h3>
                <Trans id='Race & Ethnicity'>Race & Ethnicity</Trans>
            </h3>
            <RaceComponent />
            <EthnicityComponent />
        </Box>
    )
}
