import { DataFetchParams } from '@om1/platform-routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import { DEFAULT_ASSESSMENT, HCPAssessmentType } from '../../api/hcp-assessments'
import { DEFAULT_DEMOGRAPHIC, HCPDemographicsType } from '../../api/hcp-demographics'
import {
    ChartQueryParams,
    DateRangeParam,
    ExtendedChartQueryObject,
    MSDurationData,
    Org,
    PatientBaselineDemographicsData,
    PlotlyResponse,
    RelapseHistoryAndRateData
} from './site-level-state'

export enum SiteLevelBenchmarkingActionTypes {
    GET_SITE_LEVEL_ORGANIZATIONS = '@@site-level-benchmarking/get-organizations',
    SET_SITE_LEVEL_ORGANIZATIONS = '@@site-level-benchmarking/set-organizations',
    SET_SITE_LEVEL_ORGANIZATIONS_IS_LOADING = '@@site-level-benchmarking/set-organizations-is-loading',
    GET_PATIENTS_EXPERIENCING_RELAPSE_RATE = '@@site-level-benchmarking/get-patients-experiencing-relapse-rate',
    SET_PATIENTS_EXPERIENCING_RELAPSE_RATE = '@@site-level-benchmarking/set-patients-experiencing-relapse-rate',
    SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_IS_LOADING = '@@site-level-benchmarking/set-patients-experiencing-relapse-rate-is-loading',
    SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_LAST_QUERY = '@@site-level-benchmarking/set-patients-experiencing-relapse-rate-last-query',
    GET_PATIENTS_TREATED_WITH_DMT = '@@site-level-benchmarking/get-patients-treated-with-dmt',
    SET_PATIENTS_TREATED_WITH_DMT = '@@site-level-benchmarking/set-patients-treated-with-dmt',
    SET_PATIENTS_TREATED_WITH_DMT_IS_LOADING = '@@site-level-benchmarking/set-patients-treated-with-dmt-is-loading',
    SET_PATIENTS_TREATED_WITH_DMT_LAST_QUERY = '@@site-level-benchmarking/set-patients-treated-with-dmt-last-query',
    GET_PATIENTS_EXPERIENCING_FALLS = '@@site-level-benchmarking/get-patients-experiencing-falls',
    SET_PATIENTS_EXPERIENCING_FALLS = '@@site-level-benchmarking/set-patients-experiencing-falls',
    SET_PATIENTS_EXPERIENCING_FALLS_IS_LOADING = '@@site-level-benchmarking/set-patients-experiencing-falls-is-loading',
    SET_PATIENTS_EXPERIENCING_FALLS_LAST_QUERY = '@@site-level-benchmarking/set-patients-experiencing-falls-last-query',
    GET_AVERAGE_PROS_SCORES = '@@site-level-benchmarking/get-average-pros-scores',
    SET_AVERAGE_PROS_SCORES = '@@site-level-benchmarking/set-average-pros-scores',
    SET_AVERAGE_PROS_SCORES_IS_LOADING = '@@site-level-benchmarking/set-average-pros-scores-is-loading',
    SET_AVERAGE_PROS_SCORES_LAST_QUERY = '@@site-level-benchmarking/set-average-pros-scores-last-query',
    GET_ENROLLMENT_CHART = '@@site-level-benchmarking/get-enrollment-chart',
    SET_ENROLLMENT_CHART = '@@site-level-benchmarking/set-enrollment-chart',
    SET_ENROLLMENT_CHART_IS_LOADING = '@@site-level-benchmarking/set-enrollment-chart-is-loading',
    SET_ENROLLMENT_CHART_LAST_QUERY = '@@site-level-benchmarking/set-enrollment-chart-last-query',
    GET_DMT_BREAKDOWN_BY_ROUTE_CHART = '@@site-level-benchmarking/get-dmt-breakdown-by-route-chart',
    SET_DMT_BREAKDOWN_BY_ROUTE_CHART = '@@site-level-benchmarking/set-dmt-breakdown-by-route-chart',
    SET_DMT_BREAKDOWN_BY_ROUTE_CHART_IS_LOADING = '@@site-level-benchmarking/set-dmt-breakdown-by-route-chart-is-loading',
    SET_DMT_BREAKDOWN_BY_ROUTE_CHART_LAST_QUERY = '@@site-level-benchmarking/set-dmt-breakdown-by-route-chart-last-query',
    GET_DMT_BREAKDOWN_BY_MEDICATION_CHART = '@@site-level-benchmarking/get-dmt-breakdown-by-medication-chart',
    SET_DMT_BREAKDOWN_BY_MEDICATION_CHART = '@@site-level-benchmarking/set-dmt-breakdown-by-medication-chart',
    SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_IS_LOADING = '@@site-level-benchmarking/set-dmt-breakdown-by-medication-chart-is-loading',
    SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_LAST_QUERY = '@@site-level-benchmarking/set-dmt-breakdown-by-medication-chart-last-query',
    GET_EMPLOYMENT_AND_INSURANCE = '@@site-level-benchmarking/get-employment-and-insurance',
    SET_EMPLOYMENT_AND_INSURANCE = '@@site-level-benchmarking/set-employment-and-insurance',
    SET_EMPLOYMENT_AND_INSURANCE_IS_LOADING = '@@site-level-benchmarking/set-employment-and-insurance-is-loading',
    SET_EMPLOYMENT_AND_INSURANCE_LAST_QUERY = '@@site-level-benchmarking/set-employment-and-insurance-last-query',
    GET_RELAPSE_HISTORY_AND_RATE = '@@site-level-benchmarking/get-relapse-history-and-rate-chart',
    SET_RELAPSE_HISTORY_AND_RATE = '@@site-level-benchmarking/set-relapse-history-and-rate-chart',
    SET_RELAPSE_HISTORY_AND_RATE_IS_LOADING = '@@site-level-benchmarking/set-relapse-history-and-rate-chart-is-loading',
    SET_RELAPSE_HISTORY_AND_RATE_LAST_QUERY = '@@site-level-benchmarking/set-relapse-history-and-rate-chart-last-query',
    GET_HOSPITALIZATIONS_RATE = '@@site-level-benchmarking/get-hospitalizations-rate-chart',
    SET_HOSPITALIZATIONS_RATE = '@@site-level-benchmarking/set-hospitalizations-rate-chart',
    SET_HOSPITALIZATIONS_RATE_IS_LOADING = '@@site-level-benchmarking/set-hospitalizations-rate-chart-is-loading',
    SET_HOSPITALIZATIONS_RATE_LAST_QUERY = '@@site-level-benchmarking/set-hospitalizations-rate-chart-last-query',
    GET_ER_UTILIZATIONS_RATE = '@@site-level-benchmarking/get-er-utilizations-rate-chart',
    SET_ER_UTILIZATIONS_RATE = '@@site-level-benchmarking/set-er-utilizations-rate-chart',
    SET_ER_UTILIZATIONS_RATE_IS_LOADING = '@@site-level-benchmarking/set-er-utilizations-rate-chart-is-loading',
    SET_ER_UTILIZATIONS_RATE_LAST_QUERY = '@@site-level-benchmarking/set-er-utilizations-rate-chart-last-query',
    GET_CO_ASSESSMENT = '@@site-level-benchmarking/get-co-assessment-chart',
    SET_CO_ASSESSMENT = '@@site-level-benchmarking/set-co-assessment-chart',
    SET_CO_ASSESSMENT_IS_LOADING = '@@site-level-benchmarking/set-co-assessment-chart-is-loading',
    SET_CO_ASSESSMENT_LAST_QUERY = '@@site-level-benchmarking/set-co-assessment-chart-last-query',
    GET_PT_BASELINE_DEMOGRAPHICS = '@@site-level-benchmarking/get-pt-baseline-demographics-chart',
    SET_PT_BASELINE_DEMOGRAPHICS = '@@site-level-benchmarking/set-pt-baseline-demographics-chart',
    SET_PT_BASELINE_DEMOGRAPHICS_IS_LOADING = '@@site-level-benchmarking/set-pt-baseline-demographics-is-loading',
    SET_PT_BASELINE_DEMOGRAPHICS_LAST_QUERY = '@@site-level-benchmarking/set-pt-baseline-demographics-last-query',
    GET_RACE = '@@site-level-benchmarking/get-race-chart',
    SET_RACE = '@@site-level-benchmarking/set-race-chart',
    SET_RACE_IS_LOADING = '@@site-level-benchmarking/set-race-is-loading',
    SET_RACE_LAST_QUERY = '@@site-level-benchmarking/set-race-last-query',
    GET_ETHNICITY = '@@site-level-benchmarking/get-ethnicity-chart',
    SET_ETHNICITY = '@@site-level-benchmarking/set-ethnicity-chart',
    SET_ETHNICITY_IS_LOADING = '@@site-level-benchmarking/set-ethnicity-is-loading',
    SET_ETHNICITY_LAST_QUERY = '@@site-level-benchmarking/set-ethnicity-last-query',
    GET_MS_DURATION = '@@site-level-benchmarking/get-ms-duration-chart',
    SET_MS_DURATION = '@@site-level-benchmarking/set-ms-duration-chart',
    SET_MS_DURATION_IS_LOADING = '@@site-level-benchmarking/set-ms-duration-is-loading',
    SET_MS_DURATION_LAST_QUERY = '@@site-level-benchmarking/set-ms-duration-last-query',
    GET_DEMOGRAPHICS_OVER_TIME = '@@site-level-benchmarking/get-demographics-over-time-chart',
    SET_DEMOGRAPHICS_OVER_TIME = '@@site-level-benchmarking/set-demographics-over-time-chart',
    SET_DEMOGRAPHICS_OVER_TIME_IS_LOADING = '@@site-level-benchmarking/set-demographics-over-time-chart-is-loading',
    SET_DEMOGRAPHICS_OVER_TIME_LAST_QUERY = '@@site-level-benchmarking/set-demographics-over-time-chart-last-query'
}

export const siteLevelBenchmarkingActions = {
    getOrganizations: () => createAction(SiteLevelBenchmarkingActionTypes.GET_SITE_LEVEL_ORGANIZATIONS),
    setOrganizations: (orgs: Org[]) => createAction(SiteLevelBenchmarkingActionTypes.SET_SITE_LEVEL_ORGANIZATIONS, { orgs: orgs }),
    setOrganizationIsLoading: (isOrgsLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_SITE_LEVEL_ORGANIZATIONS_IS_LOADING, { isOrgsLoading: isOrgsLoading }),
    getPatientsExperiencingRelapseRate: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_PATIENTS_EXPERIENCING_RELAPSE_RATE, {
            dateRange: params.query.patients_experiencing_relapse_chart?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setPatientsExperiencingRelapseRate: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE, {
            plotyResponse: plotyResponse
        }),
    setPatientsExperiencingRelapseRateIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_IS_LOADING, {
            isLoading: isLoading
        }),
    setPatientsExperiencingRelapseRateLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getPatientsTreatedWithDmt: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_PATIENTS_TREATED_WITH_DMT, {
            dateRange: params.query.patients_treated_with_dmt_rate_chart?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setPatientsTreatedWithDmt: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT, {
            plotyResponse: plotyResponse
        }),
    setPatientsTreatedWithDmtIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT_IS_LOADING, {
            isLoading: isLoading
        }),
    setPatientTreatmentWithDmtLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getPatientsExperiencingFalls: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_PATIENTS_EXPERIENCING_FALLS, {
            dateRange: params.query.patients_experiencing_falls_rate_chart?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setPatientsExperiencingFalls: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS, {
            plotyResponse: plotyResponse
        }),
    setPatientsExperiencingFallsIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS_IS_LOADING, {
            isLoading: isLoading
        }),
    setPatientsExperiencingFallsLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getAveragePROScores: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_AVERAGE_PROS_SCORES, {
            dateRange: params.query.average_pro_scores?.dateRange || 'all',
            pro: (params.query.average_pro_scores as ExtendedChartQueryObject)?.pro || 'PDDS',
            orgId: params.path.orgId!
        }),
    setAveragePROScores: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES, {
            plotyResponse: plotyResponse
        }),
    setAveragePROScoresIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES_IS_LOADING, {
            isLoading: isLoading
        }),
    setAveragePROScoresLastQuery: (dateRange: DateRangeParam, pro: string, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES_LAST_QUERY, {
            dateRange: dateRange,
            pro: pro,
            orgId: orgId
        }),
    getEnrollmentChart: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_ENROLLMENT_CHART, {
            dateRange: params.query.enrollment_chart?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setEnrollmentChart: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART, {
            plotyResponse: plotyResponse
        }),
    setEnrollmentChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART_IS_LOADING, {
            isLoading: isLoading
        }),
    setEnrollmentChartLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getDMTBreakdownByRouteChart: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_DMT_BREAKDOWN_BY_ROUTE_CHART, {
            orgId: params.path.orgId!
        }),
    setDMTBreakdownByRouteChart: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART, {
            plotyResponse: plotyResponse
        }),
    setDMTBreakdownByRouteChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART_IS_LOADING, {
            isLoading: isLoading
        }),
    setDMTBreakdownByRouteChartLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART_LAST_QUERY, {
            orgId: orgId
        }),
    getDMTBreakdownByMedicationChart: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_DMT_BREAKDOWN_BY_MEDICATION_CHART, {
            orgId: params.path.orgId!
        }),
    setDMTBreakdownByMedicationChart: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART, {
            plotyResponse: plotyResponse
        }),
    setDMTBreakdownByMedicationChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_IS_LOADING, {
            isLoading: isLoading
        }),
    setDMTBreakdownByMedicationChartLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_LAST_QUERY, {
            orgId: orgId
        }),
    getEmploymentAndInsuranceChart: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_EMPLOYMENT_AND_INSURANCE, {
            orgId: params.path.orgId!
        }),
    setEmploymentAndInsuranceChart: (response: { employment: PlotlyResponse; insurance: PlotlyResponse }) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE, {
            employmentPlotyResponse: response.employment,
            insurancePlotyResponse: response.insurance
        }),
    setEmploymentAndInsuranceChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE_IS_LOADING, {
            isLoading: isLoading
        }),
    setEmploymentAndInsuranceChartLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE_LAST_QUERY, {
            orgId: orgId
        }),
    getRelapseHistoryAndRate: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_RELAPSE_HISTORY_AND_RATE, {
            orgId: params.path.orgId!
        }),
    setRelapseHistoryAndRate: (data: RelapseHistoryAndRateData) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE, {
            data: data
        }),
    setRelapseHistoryAndRateIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE_IS_LOADING, {
            isLoading: isLoading
        }),
    setRelapseHistoryAndRateLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE_LAST_QUERY, {
            orgId: orgId
        }),
    getHospitalizationsRate: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_HOSPITALIZATIONS_RATE, {
            dateRange: params.query.hospitalization_rate?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setHospitalizationsRate: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE, {
            plotyResponse: plotyResponse
        }),
    setHospitalizationsRateIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE_IS_LOADING, {
            isLoading: isLoading
        }),
    setHospitalizationsRateLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getERUtilizationsRate: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_ER_UTILIZATIONS_RATE, {
            dateRange: params.query.emergency_urgentcare_visit_rate?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setERUtilizationRate: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE, {
            plotyResponse: plotyResponse
        }),
    setERUtilizationRateIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE_IS_LOADING, {
            isLoading: isLoading
        }),
    setERUtilizationRateLastQuery: (dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE_LAST_QUERY, {
            dateRange: dateRange,
            orgId: orgId
        }),
    getRaceChart: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_RACE, {
            orgId: params.path.orgId!
        }),
    setRaceChart: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RACE, {
            plotyResponse: plotyResponse
        }),
    setRaceChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RACE_IS_LOADING, {
            isLoading: isLoading
        }),
    setRaceChartLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_RACE_LAST_QUERY, {
            orgId: orgId
        }),
    getEthnicityChart: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_ETHNICITY, {
            orgId: params.path.orgId!
        }),
    setEthnicityChart: (plotyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ETHNICITY, {
            plotyResponse: plotyResponse
        }),
    setEthnicityChartIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ETHNICITY_IS_LOADING, {
            isLoading: isLoading
        }),
    setEthnicityChartLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_ETHNICITY_LAST_QUERY, {
            orgId: orgId
        }),
    getCOAssessmentCharts: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_CO_ASSESSMENT, {
            assessment: (params.query.clinical_outcomes_assessment as ExtendedChartQueryObject)?.assessment || DEFAULT_ASSESSMENT,
            orgId: params.path.orgId!
        }),
    setCOAssessmentCharts: (meanChange: PlotlyResponse, patientPercent: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT, {
            meanChange,
            patientPercent
        }),
    setCOAssessmentIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT_IS_LOADING, {
            isLoading: isLoading
        }),
    setCOAssessmentLastQuery: (assessment: HCPAssessmentType, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT_LAST_QUERY, {
            assessment,
            orgId
        }),
    getPTBaselineDemographics: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_PT_BASELINE_DEMOGRAPHICS, {
            orgId: params.path.orgId!
        }),
    setPTBaselineDemographics: (response: PatientBaselineDemographicsData) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS, {
            response: response
        }),
    setPTBaselineDemographicsIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS_IS_LOADING, {
            isLoading: isLoading
        }),
    setPTBaselineDemographicsLastQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS_LAST_QUERY, {
            orgId
        }),
    getMSDuration: (params: DataFetchParams<{}, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_MS_DURATION, {
            orgId: params.path.orgId!
        }),
    setMSDuration: (response: MSDurationData) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_MS_DURATION, {
            response: response
        }),
    setMSDurationIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_MS_DURATION_IS_LOADING, {
            isLoading: isLoading
        }),
    setMSDurationLasQuery: (orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_MS_DURATION_LAST_QUERY, {
            orgId
        }),
    getDemographicsOverTime: (params: DataFetchParams<ChartQueryParams, { orgId: string }>) =>
        createAction(SiteLevelBenchmarkingActionTypes.GET_DEMOGRAPHICS_OVER_TIME, {
            demographic: (params.query.patient_demographics_ms_duration as ExtendedChartQueryObject)?.demographic || DEFAULT_DEMOGRAPHIC,
            dateRange: params.query.patient_demographics_ms_duration?.dateRange || 'all',
            orgId: params.path.orgId!
        }),
    setDemographicsOverTime: (plotlyResponse: PlotlyResponse) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME, {
            response: plotlyResponse
        }),
    setDemographicsOverTimeIsLoading: (isLoading: boolean) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME_IS_LOADING, {
            isLoading: isLoading
        }),
    setDemographicsOverTimeLastQuery: (demographic: HCPDemographicsType, dateRange: DateRangeParam, orgId: string) =>
        createAction(SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME_LAST_QUERY, {
            demographic,
            dateRange,
            orgId
        })
}

export type SiteLevelBenchmarkingActions = ActionsUnion<typeof siteLevelBenchmarkingActions>
