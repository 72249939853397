import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { AveragePROScoresState, OrganizationsState, siteLevelBenchmarkingActions } from '../state'
import { AveragePROScoresComponent } from './AveragePROScoresComponent'

export function createAveragePROScoresComponent<TState extends { averageproscores: AveragePROScoresState; organizations: OrganizationsState }>() {
    return connectRedux(
        withRouter(AveragePROScoresComponent, { namespace: 'pros', dataFetchAction: 'getAveragePROScores' }),
        (state: TState) => {
            return { ...state.averageproscores, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type AveragePROScores = ReturnType<typeof createAveragePROScoresComponent>
