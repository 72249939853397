import { FunctionComponent, PropsWithChildren } from 'react'
import { Box } from '@mui/system'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { t } from '@lingui/macro'
import { HCPDemographicsType, HCP_DEMOGRAPHICS } from '../../api/hcp-demographics'

export const DemographicSelectorComponent: FunctionComponent<
    PropsWithChildren<{ handleChange: Function; selectedDemographic: HCPDemographicsType; isLoading?: boolean }>
> = (props) => {
    const { selectedDemographic, handleChange } = props

    const onChange = (event: SelectChangeEvent) => {
        handleChange(event.target.value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                marginBottom: '20px'
            }}
        >
            <Select
                id='pro-select'
                sx={{ width: '100%' }}
                value={selectedDemographic}
                label={t`Select PRO`}
                onChange={onChange}
                disabled={props.isLoading}
            >
                {Object.keys(HCP_DEMOGRAPHICS).map((demographic) => (
                    <MenuItem key={demographic} value={demographic}>
                        {demographic}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    )
}
