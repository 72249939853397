import { ActionsUnion, createAction } from '@om1/platform-utils'
import { KeycloakProfile } from 'keycloak-js'
export interface KeycloakState {
    idToken?: string | undefined
    refreshToken?: string | undefined
    token?: string | undefined
    profile?: KeycloakProfile
    isSessionExpired?: boolean
}

export const initialKeycloakState: KeycloakState = {}

export const getKeycloakState = (state: { keycloak: KeycloakState }) => state.keycloak

export enum KeycloakActionTypes {
    SET_KEYCLOAK_TOKENS = '@@keycloak/set-tokens',
    SET_KEYCLOAK_PROFILE = '@@keycloak/set-profile',
    LOGOUT = '@@keycloak/logout',
    LOGIN = '@@keycloak/login',
    SESSION_EXPIRED = '@@keycloak/session-expired'
}

export function keycloakReducer(previousState: KeycloakState | undefined, action: KeycloakActions): KeycloakState {
    const state = previousState || initialKeycloakState

    switch (action.type) {
        case KeycloakActionTypes.SET_KEYCLOAK_TOKENS:
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
                idToken: action.payload.idToken
            }
        case KeycloakActionTypes.SET_KEYCLOAK_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            }
        case KeycloakActionTypes.LOGOUT:
            return initialKeycloakState
        case KeycloakActionTypes.SESSION_EXPIRED:
            return {
                ...state,
                isSessionExpired: true
            }
        default:
            return state
    }
}

export const keycloakActions = {
    setKeycloakTokens: (idToken?: string | undefined, refreshToken?: string | undefined, token?: string | undefined) =>
        createAction(KeycloakActionTypes.SET_KEYCLOAK_TOKENS, { idToken: idToken, refreshToken: refreshToken, token: token }),
    setKeycloakProfile: (profile: KeycloakProfile) => createAction(KeycloakActionTypes.SET_KEYCLOAK_PROFILE, { profile: profile }),
    logout: () => createAction(KeycloakActionTypes.LOGOUT),
    login: () => createAction(KeycloakActionTypes.LOGIN),
    sessionExpired: () => createAction(KeycloakActionTypes.SESSION_EXPIRED)
}

export type KeycloakActions = ActionsUnion<typeof keycloakActions>
