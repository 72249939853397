import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, PatientsTreatedWithDmtState, siteLevelBenchmarkingActions } from '../state'
import { PatientsTreatedWithDmtComponent } from './PatientsTreatedWithDmtComponent'

export function createPatientsTreatedWithDmtComponent<
    TState extends { patientstreatedwithdmt: PatientsTreatedWithDmtState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(PatientsTreatedWithDmtComponent, {
            namespace: 'clinicalmanagement',
            dataFetchAction: 'getPatientsTreatedWithDmt'
        }),
        (state: TState) => {
            return { ...state.patientstreatedwithdmt, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type PatientsExperiencingRelapseRate = ReturnType<typeof createPatientsTreatedWithDmtComponent>
