import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { RaceAPI, SiteLevelBenchmarkingService } from '../service'
import { RaceState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getRace = (state: { race: RaceState }) => state.race

export function createRaceSaga(siteLevelBenchmarkingService: SiteLevelBenchmarkingService, getToken: () => string | undefined) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getRaceChart>) {
        try {
            const race: RaceState = yield select(getRace)
            if (race.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setRaceChartIsLoading(true))
                const result: RaceAPI.Responses = yield call(siteLevelBenchmarkingService.raceAPI, getToken(), action.payload.orgId)
                if (result.type === RaceAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setRaceChart(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setRaceChartLastQuery(action.payload.orgId))
                } else if (result.type === RaceAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === RaceAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in RaceAPI'))
                } else if (result.type === RaceAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in raceSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setRaceChartIsLoading(false))
        }
    }
}

export type raceSaga = ReturnType<typeof createRaceSaga>
