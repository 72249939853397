export const HCP_ASSESSMENTS = [
    'Symbol Digit Modality Test',
    'Montreal Cognitive Assessment',
    'Processing Speed Test',
    'Paced Serial Addition Test',
    'Extended Disability Status Scale',
    'Modified Extended Disability Status Scale',
    'PDDS',
    'HRQoL',
    '9-Hole Pegboard Test',
    'Timed 25 Foot Walk'
] as const
export const DEFAULT_ASSESSMENT = HCP_ASSESSMENTS[0]
export type HCPAssessmentType = typeof HCP_ASSESSMENTS[number]
