import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, PatientsExperiencingFallsState, siteLevelBenchmarkingActions } from '../state'
import { PatientsExperiencingFallsComponent } from './PatientsExperiencingFallsComponent'

export function createPatientsExperiencingFallsComponent<
    TState extends { patientsexperiencingfalls: PatientsExperiencingFallsState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(PatientsExperiencingFallsComponent, {
            namespace: 'clinicalmanagement',
            dataFetchAction: 'getPatientsExperiencingFalls'
        }),
        (state: TState) => {
            return { ...state.patientsexperiencingfalls, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type PatientsExperiencingFalls = ReturnType<typeof createPatientsExperiencingFallsComponent>
