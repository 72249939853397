import { HealthcheckActionTypes } from '@om1/healthcheck-module'
import { createNotificationSaga, noticiationReducer, notificationActions } from '@om1/platform-notifications'
import { configureStore } from '@reduxjs/toolkit'
import { RouterState, connectRouter } from 'connected-react-router'
import { LocationState } from 'history'
import Keycloak from 'keycloak-js'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { AnyAction, CombinedState, Middleware, Reducer, applyMiddleware, combineReducers } from 'redux'
import dynamicMiddlewares from 'redux-dynamic-middlewares'
import { SagaMiddleware } from 'redux-saga'
import { all, put, spawn } from 'redux-saga/effects'
import { HcpApi } from './api/hcp-fetch'
import keepAlive from './keep-alive'
import { createSiteLevelBenchmarkingSaga } from './module/saga'
import {
    AveragePROScoresState,
    ClinicalAssessmentState,
    DMTBreakdownByMedicationState,
    DMTBreakdownByRouteState,
    DemographicsOverTimeState,
    ERUtilizationsRateState,
    EmploymentAndInsuranceState,
    EnrollmentChartState,
    EthnicityState,
    HospitalizationsRateState,
    MSDurationState,
    OrganizationsState,
    PatientBaselineDemographicsState,
    PatientsExperiencingFallsState,
    PatientsExperiencingRelapseRateState,
    PatientsTreatedWithDmtState,
    RaceState,
    RelapseHistoryAndRateState,
    averagePROSScoresReducer,
    clinicalOutcomesAssessmentReducer,
    demographicsOverTimeReducer,
    dmtBreakdownByMedicationChartReducer,
    dmtBreakdownByRouteChartReducer,
    employmentAndInsuranceReducer,
    enrollmentChartReducer,
    erUtilizationsRateReducer,
    ethnicityReducer,
    hospitalizationsRateReducer,
    msDurationReducer,
    organizationsReducer,
    patientBaselineDemographicsReducer,
    patientsExperiencingFallsReducer,
    patientsExperiencingRelapseReducer,
    patientsTreatedWithDmtReducer,
    raceReducer,
    relapseHistoryAndRateReducer
} from './module/state'
import { KeycloakActionTypes, KeycloakState, createKeycloakSaga, keycloakReducer } from './shared/authentication'
import { ConfigState, configReducer } from './shared/config'
import { languageReducer } from './shared/providers/internationalization/language-reducer'
import { LanguageState } from './shared/providers/internationalization/language-state'

export const createReducer = (history: any) =>
    combineReducers({
        router: connectRouter(history),
        language: languageReducer,
        keycloak: keycloakReducer,
        notification: noticiationReducer,
        // modules:
        patientsexperiencingrelapse: patientsExperiencingRelapseReducer,
        patientstreatedwithdmt: patientsTreatedWithDmtReducer,
        patientsexperiencingfalls: patientsExperiencingFallsReducer,
        averageproscores: averagePROSScoresReducer,
        organizations: organizationsReducer,
        enrollmentChart: enrollmentChartReducer,
        dmtBreakdownByRoute: dmtBreakdownByRouteChartReducer,
        dmtBreakdownByMedication: dmtBreakdownByMedicationChartReducer,
        employmentAndInsurance: employmentAndInsuranceReducer,
        relapseHistoryAndRate: relapseHistoryAndRateReducer,
        hospitalizations: hospitalizationsRateReducer,
        erUtilizations: erUtilizationsRateReducer,
        race: raceReducer,
        ethnicity: ethnicityReducer,
        coAssessment: clinicalOutcomesAssessmentReducer,
        ptBaselineDemographics: patientBaselineDemographicsReducer,
        msDuration: msDurationReducer,
        demographicsOverTime: demographicsOverTimeReducer,
        config: configReducer
    })
export const appReducer = createReducer(window.history)

export type SiteLevelBenchmarkingState = ReturnType<typeof appReducer>

export function createAppStore(
    reducer: Reducer<
        CombinedState<{
            router: RouterState<LocationState>
            language: LanguageState
            keycloak: KeycloakState
            notification: {}
            // modules:
            patientsexperiencingrelapse: PatientsExperiencingRelapseRateState
            patientstreatedwithdmt: PatientsTreatedWithDmtState
            patientsexperiencingfalls: PatientsExperiencingFallsState
            averageproscores: AveragePROScoresState
            organizations: OrganizationsState
            enrollmentChart: EnrollmentChartState
            dmtBreakdownByRoute: DMTBreakdownByRouteState
            dmtBreakdownByMedication: DMTBreakdownByMedicationState
            employmentAndInsurance: EmploymentAndInsuranceState
            relapseHistoryAndRate: RelapseHistoryAndRateState
            hospitalizations: HospitalizationsRateState
            erUtilizations: ERUtilizationsRateState
            race: RaceState
            ethnicity: EthnicityState
            coAssessment: ClinicalAssessmentState
            ptBaselineDemographics: PatientBaselineDemographicsState
            msDuration: MSDurationState
            demographicsOverTime: DemographicsOverTimeState
            config: ConfigState
        }>,
        AnyAction
    >,
    sagaMiddleware: SagaMiddleware,
    ...additionalMiddlewares: Middleware[]
) {
    const middlewares = [sagaMiddleware, dynamicMiddlewares, ...additionalMiddlewares]
    return configureStore({
        reducer: reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
        enhancers: [applyMiddleware(...middlewares)]
    })
}

export type SiteLevelBenchmarkingAppStoreActions = HealthcheckActionTypes | KeycloakActionTypes

export type SiteLevelBenchmarkingAppStore = ReturnType<typeof createAppStore>

function createOnFail(sagaName: string) {
    function* onFail(error) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        const errorMessage = `${sagaName} module has produced too many uncaught errors and is shutting down, these exceptions should be handled.`
        yield console.warn(errorMessage)
        yield console.error(error)
        yield put(notificationActions.error(errorMessage))
    }
    function* onEachError(_next, error, _saga) {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        const errorMessage = `${sagaName} module has produced an error and is restarting, these exceptions should be handled.`
        yield console.warn(errorMessage)
        yield console.error(error)
        yield put(notificationActions.warning(errorMessage))
    }
    return { onFail, onEachError, disableWarnings: true }
}

export function createRootSaga(
    keycloak: Keycloak.KeycloakInstance,
    hcpApi: HcpApi,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
) {
    const keycloakSaga = createKeycloakSaga(keycloak)
    const getToken = () => {
        return keycloak.token
    }
    const notificationSaga = createNotificationSaga(enqueueSnackbar)
    // modules:
    const siteLevelBenchmarkingSaga = createSiteLevelBenchmarkingSaga(hcpApi, getToken)

    let sagas = [
        { saga: keycloakSaga, name: 'Keycloak' },
        { saga: notificationSaga, name: 'Notifications' },
        { saga: siteLevelBenchmarkingSaga, name: 'SiteLevelBenchmarking' }
    ]

    return function* () {
        yield all(sagas.map((saga) => spawn(keepAlive(saga.saga, createOnFail(saga.name)))))
    }
}
