import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { PatientBaselineDemographicsAPI, SiteLevelBenchmarkingService } from '../service'
import { PatientBaselineDemographicsState, siteLevelBenchmarkingActions } from '../state'
import { keycloakActions } from '../../shared/authentication'

export const getPatientBaselineDemographicsChart = (state: { ptBaselineDemographics: PatientBaselineDemographicsState }) =>
    state.ptBaselineDemographics

export function createPatientBaselineDemographicsChartSaga(
    siteLevelBenchmarkingService: SiteLevelBenchmarkingService,
    getToken: () => string | undefined
) {
    return function* (action: ReturnType<typeof siteLevelBenchmarkingActions.getPTBaselineDemographics>) {
        try {
            const ptBaselineDemographics: PatientBaselineDemographicsState = yield select(getPatientBaselineDemographicsChart)
            if (ptBaselineDemographics.lastOrgId !== action.payload.orgId) {
                yield put(siteLevelBenchmarkingActions.setPTBaselineDemographicsIsLoading(true))
                const result: PatientBaselineDemographicsAPI.Responses = yield call(
                    siteLevelBenchmarkingService.ptBaselineDemographicsAPI,
                    getToken(),
                    action.payload.orgId
                )
                if (result.type === PatientBaselineDemographicsAPI.SUCCESS) {
                    yield put(siteLevelBenchmarkingActions.setPTBaselineDemographics(result.body.response))
                    yield put(siteLevelBenchmarkingActions.setPTBaselineDemographicsLastQuery(action.payload.orgId))
                } else if (result.type === PatientBaselineDemographicsAPI.UNAUTHORIZED) {
                    yield put(keycloakActions.sessionExpired())
                } else if (result.type === PatientBaselineDemographicsAPI.NOTFOUND) {
                    yield put(notificationActions.error('404: Invalid Org Id in PatientBaselineDemographicsAPI'))
                } else if (result.type === PatientBaselineDemographicsAPI.ERROR) {
                    yield put(notificationActions.error('500: ERROR in createPatientBaselineDemographicsChartSaga'))
                }
            }
        } catch (e) {
            yield put(notificationActions.error((e as Error).message))
        } finally {
            yield put(siteLevelBenchmarkingActions.setPTBaselineDemographicsIsLoading(false))
        }
    }
}

export type DMTBreakdownByRouteChartSaga = ReturnType<typeof createPatientBaselineDemographicsChartSaga>
