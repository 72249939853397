import { SiteLevelBenchmarkingActionTypes, SiteLevelBenchmarkingActions } from './site-level-actions'
import {
    AveragePROScoresState,
    ClinicalAssessmentState,
    DMTBreakdownByMedicationState,
    DMTBreakdownByRouteState,
    DemographicsOverTimeState,
    ERUtilizationsRateState,
    EmploymentAndInsuranceState,
    EnrollmentChartState,
    EthnicityState,
    HospitalizationsRateState,
    MSDurationState,
    OrganizationsState,
    PatientBaselineDemographicsState,
    PatientsExperiencingFallsState,
    PatientsExperiencingRelapseRateState,
    PatientsTreatedWithDmtState,
    RaceState,
    RelapseHistoryAndRateState,
    initialAveragePROScoresState,
    initialClinicalOutcomesAssessmentState,
    initialDMTBreakdownByMedicationState,
    initialDMTBreakdownByRouteState,
    initialDemographicsOverTimeState,
    initialERUtilizationsRateState,
    initialEmploymentAndInsuranceState,
    initialEnrollmentChartState,
    initialEthnicityState,
    initialHospitalizationsRateState,
    initialMSDurationState,
    initialOrganizationsState,
    initialPatientBaselineDemographicsState,
    initialPatientsExperiencingFallsState,
    initialPatientsExperiencingRelapseState,
    initialPatientsTreatedWithDmtState,
    initialRaceState,
    initialRelapseHistoryAndState
} from './site-level-state'

export function patientsExperiencingRelapseReducer(
    previousState: PatientsExperiencingRelapseRateState | undefined,
    action: SiteLevelBenchmarkingActions
): PatientsExperiencingRelapseRateState {
    const state = previousState || initialPatientsExperiencingRelapseState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_RELAPSE_RATE_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function averagePROSScoresReducer(
    previousState: AveragePROScoresState | undefined,
    action: SiteLevelBenchmarkingActions
): AveragePROScoresState {
    const state = previousState || initialAveragePROScoresState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_AVERAGE_PROS_SCORES_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId,
                lastPro: action.payload.pro
            }
        default:
            return state
    }
}

export function patientsTreatedWithDmtReducer(
    previousState: PatientsTreatedWithDmtState | undefined,
    action: SiteLevelBenchmarkingActions
): PatientsTreatedWithDmtState {
    const state = previousState || initialPatientsTreatedWithDmtState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_TREATED_WITH_DMT_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function patientsExperiencingFallsReducer(
    previousState: PatientsExperiencingFallsState | undefined,
    action: SiteLevelBenchmarkingActions
): PatientsExperiencingFallsState {
    const state = previousState || initialPatientsExperiencingFallsState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_PATIENTS_EXPERIENCING_FALLS_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function organizationsReducer(previousState: OrganizationsState | undefined, action: SiteLevelBenchmarkingActions): OrganizationsState {
    const state = previousState || initialOrganizationsState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_SITE_LEVEL_ORGANIZATIONS:
            return {
                ...state,
                orgs: action.payload.orgs
            }
        case SiteLevelBenchmarkingActionTypes.SET_SITE_LEVEL_ORGANIZATIONS_IS_LOADING:
            return {
                ...state,
                isOrgsLoading: action.payload.isOrgsLoading
            }
        default:
            return state
    }
}

export function enrollmentChartReducer(previousState: EnrollmentChartState | undefined, action: SiteLevelBenchmarkingActions): EnrollmentChartState {
    const state = previousState || initialEnrollmentChartState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_ENROLLMENT_CHART_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function dmtBreakdownByMedicationChartReducer(
    previousState: DMTBreakdownByMedicationState | undefined,
    action: SiteLevelBenchmarkingActions
): DMTBreakdownByMedicationState {
    const state = previousState || initialDMTBreakdownByMedicationState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_MEDICATION_CHART_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function employmentAndInsuranceReducer(
    previousState: EmploymentAndInsuranceState | undefined,
    action: SiteLevelBenchmarkingActions
): EmploymentAndInsuranceState {
    const state = previousState || initialEmploymentAndInsuranceState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE:
            return {
                ...state,
                employment: action.payload.employmentPlotyResponse,
                insurance: action.payload.insurancePlotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_EMPLOYMENT_AND_INSURANCE_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function dmtBreakdownByRouteChartReducer(
    previousState: DMTBreakdownByRouteState | undefined,
    action: SiteLevelBenchmarkingActions
): DMTBreakdownByRouteState {
    const state = previousState || initialDMTBreakdownByRouteState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_DMT_BREAKDOWN_BY_ROUTE_CHART_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function relapseHistoryAndRateReducer(
    previousState: RelapseHistoryAndRateState | undefined,
    action: SiteLevelBenchmarkingActions
): RelapseHistoryAndRateState {
    const state = previousState || initialRelapseHistoryAndState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE:
            return {
                ...state,
                data: action.payload.data
            }
        case SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_RELAPSE_HISTORY_AND_RATE_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function hospitalizationsRateReducer(
    previousState: HospitalizationsRateState | undefined,
    action: SiteLevelBenchmarkingActions
): HospitalizationsRateState {
    const state = previousState || initialHospitalizationsRateState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_HOSPITALIZATIONS_RATE_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function erUtilizationsRateReducer(
    previousState: ERUtilizationsRateState | undefined,
    action: SiteLevelBenchmarkingActions
): ERUtilizationsRateState {
    const state = previousState || initialERUtilizationsRateState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_ER_UTILIZATIONS_RATE_LAST_QUERY:
            return {
                ...state,
                lastDateRange: action.payload.dateRange,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function raceReducer(previousState: RaceState | undefined, action: SiteLevelBenchmarkingActions): RaceState {
    const state = previousState || initialRaceState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_RACE:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_RACE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_RACE_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function ethnicityReducer(previousState: EthnicityState | undefined, action: SiteLevelBenchmarkingActions): EthnicityState {
    const state = previousState || initialEthnicityState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_ETHNICITY:
            return {
                ...state,
                data: action.payload.plotyResponse
            }
        case SiteLevelBenchmarkingActionTypes.SET_ETHNICITY_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_ETHNICITY_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function clinicalOutcomesAssessmentReducer(
    previousState: ClinicalAssessmentState | undefined,
    action: SiteLevelBenchmarkingActions
): ClinicalAssessmentState {
    const state = previousState || initialClinicalOutcomesAssessmentState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT:
            return {
                ...state,
                meanChange: action.payload.meanChange,
                patientPecent: action.payload.patientPercent
            }
        case SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_CO_ASSESSMENT_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId,
                lastAssessment: action.payload.assessment
            }
        default:
            return state
    }
}

export function patientBaselineDemographicsReducer(
    previousState: PatientBaselineDemographicsState | undefined,
    action: SiteLevelBenchmarkingActions
): PatientBaselineDemographicsState {
    const state = previousState || initialPatientBaselineDemographicsState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS:
            return {
                ...state,
                data: action.payload.response
            }
        case SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_PT_BASELINE_DEMOGRAPHICS_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function msDurationReducer(previousState: MSDurationState | undefined, action: SiteLevelBenchmarkingActions): MSDurationState {
    const state = previousState || initialMSDurationState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_MS_DURATION:
            return {
                ...state,
                data: action.payload.response
            }
        case SiteLevelBenchmarkingActionTypes.SET_MS_DURATION_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_MS_DURATION_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId
            }
        default:
            return state
    }
}

export function demographicsOverTimeReducer(
    previousState: DemographicsOverTimeState | undefined,
    action: SiteLevelBenchmarkingActions
): DemographicsOverTimeState {
    const state = previousState || initialDemographicsOverTimeState

    switch (action.type) {
        case SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME:
            return {
                ...state,
                data: action.payload.response
            }
        case SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case SiteLevelBenchmarkingActionTypes.SET_DEMOGRAPHICS_OVER_TIME_LAST_QUERY:
            return {
                ...state,
                lastOrgId: action.payload.orgId,
                lastDateRange: action.payload.dateRange,
                lastDemographic: action.payload.demographic
            }
        default:
            return state
    }
}
