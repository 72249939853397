export const HCP_PROS = [
    'PDDS',
    'HRQoL',
    'PROMIS-Fatigue-MS',
    'PROMIS-Anxiety-SF-8a',
    'PROMIS-Cognition-SF-8a',
    'PHQ-9',
    'Wasson-Confidence',
    'MS-TAQ',
    'WPAI-MS',
    'PROMIS-Physical-SF15'
]
