import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { OrganizationsState, PatientBaselineDemographicsState, siteLevelBenchmarkingActions } from '../state'
import { PatientBaselineDemographicsComponent } from './PatientBaselineDemographicsComponent'

export function createPatientBaselineDemographicsComponent<
    TState extends { ptBaselineDemographics: PatientBaselineDemographicsState; organizations: OrganizationsState }
>() {
    return connectRedux(
        withRouter(PatientBaselineDemographicsComponent, { namespace: 'population', dataFetchAction: 'getPTBaselineDemographics' }),
        (state: TState) => {
            return { ...state.ptBaselineDemographics, ...state.organizations }
        },
        siteLevelBenchmarkingActions
    )
}

export type patientBaselineDemographics = ReturnType<typeof createPatientBaselineDemographicsComponent>
