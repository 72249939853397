import { Box } from '@mui/system'
import { createDMTBreakdownByMedicationComponent } from '../../module/components/DMTBreakdownByMedication'
import { createDMTBreakdownByRouteComponent } from '../../module/components/DMTBreakdownByRoute'
import { createDemographicsOverTimeComponent } from '../../module/components/DemographicsOverTime'
import { createEmploymentAndInsuranceComponent } from '../../module/components/EmploymentAndInsurance'
import { createMSDurationComponent } from '../../module/components/MSDuration'
import { createPatientBaselineDemographicsComponent } from '../../module/components/PatientBaselineDemographics'
import { RaceEthnicityComponent } from '../../module/components/RaceEthnicityComponent'
import { SiteLevelBenchmarkingState } from '../../site-level-benchmarking-state'

const PatientBaselineDemographicsComponent = createPatientBaselineDemographicsComponent<SiteLevelBenchmarkingState>()
const DemographicsOverTimeComponent = createDemographicsOverTimeComponent<SiteLevelBenchmarkingState>()
const DMTBreakdownByRouteComponent = createDMTBreakdownByRouteComponent<SiteLevelBenchmarkingState>()
const DMTBreakdownByMedicationComponent = createDMTBreakdownByMedicationComponent<SiteLevelBenchmarkingState>()
const EmploymentAndInsuranceComponent = createEmploymentAndInsuranceComponent<SiteLevelBenchmarkingState>()
const MSDurationComponent = createMSDurationComponent<SiteLevelBenchmarkingState>()

export function PopulationPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0
            }}
        >
            <PatientBaselineDemographicsComponent />
            <DemographicsOverTimeComponent />
            <RaceEthnicityComponent />
            <EmploymentAndInsuranceComponent />
            <DMTBreakdownByRouteComponent />
            <DMTBreakdownByMedicationComponent />
            <MSDurationComponent />
        </Box>
    )
}
